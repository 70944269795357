import { useCallback } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { noop } from 'lodash';
import { Typography, TextField } from '@mui/material';
import DialogActions from '../shared/Dialog/DialogActions';
import FieldLabel from '../shared/FieldLabel';
import Select from '../shared/Select';
import { SaveTarget, useFeature, useTemplateCategories } from '../../hooks';
import { FeatureType, TemplateJoinType } from '../../generated/types';
import { BaseRegisteredDialogComponentProps } from './types';

export interface TemplateParams {
  name: string;
  description: string;
  categoryId?: string;
}

export interface SaveAsTemplateDialogProps
  extends BaseRegisteredDialogComponentProps {
  defaultName: string;
  defaultParams: {
    featureType: FeatureType;
    target: SaveTarget;
    entityId: string;
    joinType: TemplateJoinType;
  };
}

const SaveAsTemplateDialog = ({
  hideDialog,
  defaultName,
  defaultParams,
}: SaveAsTemplateDialogProps) => {
  const {
    selectedId: selectedCategoryId,
    categoryNames,
    selectByName: selectCategoryByName,
  } = useTemplateCategories();

  const { saveFeatureAs } = useFeature();

  const validationSchema = yup.object({
    name: yup
      .string()
      .label('Name')
      .min(3)
      .required()
      .matches(/^\S+.+$/, "Name can't start with a space"),
    description: yup
      .string()
      .label('Description')
      .max(2000)
      .required()
      .matches(/^\S+.*$/, "Description can't start with a space"),
    category: yup.string().label('Category'),
  });

  const formik = useFormik({
    initialValues: {
      name: defaultName,
      description: '',
      category: '',
    },
    onSubmit: noop,
    validationSchema: validationSchema,
    validateOnMount: true,
  });

  const saveAsTemplate = useCallback(() => {
    if (formik.isValid) {
      saveFeatureAs({
        ...defaultParams,
        categoryId: selectedCategoryId,
        nameOverride: formik.values.name,
        descriptionOverride: formik.values.description,
      });
    }
  }, [
    defaultParams,
    formik.isValid,
    formik.values.description,
    formik.values.name,
    saveFeatureAs,
    selectedCategoryId,
  ]);

  const categoryDisabled = categoryNames.length === 0;

  return (
    <form>
      <FieldLabel title='Template Name' showAsterisk={true} />
      <TextField
        name='name'
        type='text'
        value={formik.values.name}
        onChange={formik.handleChange}
        placeholder={'Enter template name'}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
        fullWidth
        sx={{
          '& .MuiOutlinedInput-input': {
            height: 6,
          },
        }}
      />
      <FieldLabel
        sx={{ mt: 3, mb: 1.5 }}
        title='Description'
        showAsterisk={true}
      />
      <TextField
        name='description'
        sx={{ width: '100%' }}
        value={formik.values.description}
        onChange={formik.handleChange}
        error={Boolean(formik.errors.description)}
        helperText={formik.errors.description}
        multiline
        rows={4}
        placeholder={'Maximum of 2000 characters.'}
      />
      <FieldLabel sx={{ mt: 3 }} title='Category' />
      <Typography variant='body1' mb={1}>
        Select a category to associate with the template
      </Typography>
      <Select
        className='select-template-category'
        inputClassName='select-template-category-input'
        options={categoryNames}
        value={formik.values.category}
        error={Boolean(formik.errors.category)}
        helperText={formik.errors.category}
        label={
          categoryDisabled
            ? 'There is no template category'
            : 'Select template category'
        }
        setValue={(value) => {
          selectCategoryByName(value);
          formik.setFieldValue('category', value);
        }}
        disabled={categoryDisabled}
      />
      <DialogActions
        closeDialog={hideDialog}
        secondaryAction={{
          text: 'Cancel',
        }}
        primaryAction={{
          text: 'Save as new template',
          action: saveAsTemplate,
        }}
      />
    </form>
  );
};

export default SaveAsTemplateDialog;
