import { ChipProps as MuiChipProps } from '@mui/material/Chip';
import { Typography } from '@mui/material';
import Button, { ButtonProps } from '../Button';
import Icon from '../Icon';
import { IconName } from '../Icon/types';

export interface ChipProps extends Omit<ButtonProps, 'variant' | 'size'> {
  size?: 'small' | 'large';
  label: MuiChipProps['label'];
  onDelete?: MuiChipProps['onDelete'];
  iconName?: IconName;
  selected?: boolean;
  hasError?: boolean;
  disabled?: boolean;
}

type ChipState = 'ENABLED' | 'HAS_ERROR' | 'DISABLED' | 'HOVERED' | 'FOCUSED';
type SelectedState = 'DEFAULT' | 'SELECTED';

const Chip = ({
  label,
  size = 'small',
  iconName,
  onDelete,
  onClick,
  selected,
  disabled,
  hasError,
  sx,
  ...rest
}: ChipProps) => {
  const disabledState: ChipState = disabled ? 'DISABLED' : 'ENABLED';
  const chipState: ChipState = hasError ? 'HAS_ERROR' : disabledState;
  const selectedState: SelectedState = selected ? 'SELECTED' : 'DEFAULT';

  const colorsByState: Record<
    ChipState,
    Record<SelectedState, Record<string, string>>
  > = {
    ENABLED: {
      DEFAULT: {
        borderColor: 'border.actionable',
        backgroundColor: 'background.paper',
      },
      SELECTED: {
        borderColor: 'border.active',
        backgroundColor: 'primary.wash',
      },
    },
    HAS_ERROR: {
      DEFAULT: {
        borderColor: 'error.main',
        backgroundColor: 'background.paper',
      },
      SELECTED: {
        borderColor: 'error.main',
        backgroundColor: 'error.wash',
      },
    },
    DISABLED: {
      DEFAULT: {
        borderColor: 'transparent',
        backgroundColor: 'background.disabled',
      },
      SELECTED: {
        borderColor: 'border.disabled',
        backgroundColor: 'background.disabled',
      },
    },
    HOVERED: {
      DEFAULT: {
        borderColor: 'border.actionable',
        backgroundColor: 'primary.wash',
      },
      SELECTED: {
        borderColor: 'border.actionable',
        backgroundColor: 'primary.wash',
      },
    },
    FOCUSED: {
      DEFAULT: {
        borderColor: 'border.actionable',
        backgroundColor: 'background.paper',
      },
      SELECTED: {
        borderColor: 'border.active',
        backgroundColor: 'primary.wash',
      },
    },
  };

  const styleBySize = {
    small: {
      padding: '3px 5px',
      '& .MuiButton-startIcon': {
        marginLeft: 0, // remove negative margin
        marginRight: '4px',
      },
      '& .MuiButton-endIcon': {
        marginRight: 0, // remove negative margin
        marginLeft: '4px',
      },
    },
    large: {
      padding: '7px 10px',
      '& .MuiButton-startIcon': {
        marginLeft: 0, // remove negative margin
        marginRight: '8px',
      },
      '& .MuiButton-endIcon': {
        marginLeft: '8px', // remove negative margin
        marginRight: 0,
      },
    },
  };

  return (
    <Button
      variant='outlined'
      size={size}
      disabled={disabled}
      aria-label={`${label}${selected ? '-selected' : ''}`}
      startIcon={iconName ? <Icon name={iconName} /> : undefined}
      endIcon={
        onDelete ? (
          <Icon name='close' onClick={onDelete} sx={{ cursor: 'pointer' }} />
        ) : undefined
      }
      onClick={onClick}
      sx={{
        ...sx,
        // static styles
        borderRadius: 2,
        whiteSpace: 'nowrap',
        minWidth: 'unset',
        // dynamic styles
        cursor: onClick ? 'pointer' : 'default',
        color: selected ? 'text.primary' : 'text.secondary',
        // border and background color
        ...colorsByState[chipState][selectedState],
        '&:hover': {
          ...colorsByState['HOVERED'][selectedState],
        },
        '&:focus': {
          ...colorsByState['FOCUSED'][selectedState],
          boxShadow: (theme) =>
            `0px 0px 0px 2px ${theme.palette.primary.light} !important`,
        },
        '&.Mui-disabled': {
          ...colorsByState['DISABLED'][selectedState],
        },
        ...styleBySize[size],
      }}
      {...rest}
    >
      <Typography variant={size === 'small' ? 'body2' : 'body1'}>
        {label}
      </Typography>
    </Button>
  );
};

export default Chip;
