import { onError } from '@apollo/client/link/error';
import { deleteCurrentUserDetails, deleteTokens } from '../localStorage';
import { ResetStoreAction, store } from '../../store';
import { clearSessionUser } from '../../store/slices/session';
import { setError } from '../../store/slices/error';
import * as Sentry from '@sentry/react';
import LogRocket from 'logrocket';

interface GraphQLException {
  stacktrace: string[];
}

export const errorLink = onError(
  ({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
            locations,
            null,
            2,
          )}, Path: ${path}, Input:`,
          operation.variables?.input || operation.variables,
        );
        store.dispatch(setError(message));

        // Do not over-report to Sentry GQL errors that are considered normal in the course of GQL exchange with the BE,
        // (i.e. anything that's APIError-based whose user friendly message is communicated with end-user using toast anyway)
        //
        // Report only those internal server errors and errors with unknown error code instead
        if (extensions?.code === 'INTERNAL_SERVER_ERROR') {
          Sentry.withScope((scope) => {
            scope.setExtra('sessionURL', LogRocket.sessionURL);
            scope.setExtra('locations', JSON.stringify(locations));
            scope.setExtra('path', JSON.stringify(path));
            if (extensions?.exception) {
              const customException = extensions.exception as GraphQLException;
              scope.setExtra(
                'stacktrace',
                JSON.stringify(customException?.stacktrace),
              );
            }
            const traceId =
              operation.getContext().headers?.['inventive-trace-id'];
            if (traceId) {
              scope.setTag('inventive_trace_id', traceId);
            }
            Sentry.captureMessage(`GraphQL error: ${message}`);
          });
        }
      });
    }

    // always log network errors
    if (networkError) {
      Sentry.captureException(networkError);

      if (
        networkError.message.includes('401') &&
        operation.operationName !== 'SignIn'
      ) {
        store.dispatch(clearSessionUser());
        store.dispatch(setError(networkError.message));
        //clear the whole store after 3 sec -- leave enought time for user to see error message
        setTimeout(() => {
          store.dispatch(ResetStoreAction);
        }, 5000);

        deleteCurrentUserDetails();
        deleteTokens();
        window.location.href = '/';
      }
    }
  },
);
