import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useCallback,
  useRef,
} from 'react';
import isEqual from 'lodash/isEqual';
import Drawer, {
  DrawerProps,
  FormikDrawerConfig,
  NonFormikDrawerConfig,
} from '../components/shared/Drawer';
import { DRAWER_IDS } from '../components/registeredDrawers/drawerRegistry';

type Action<ID extends DRAWER_IDS> =
  | { type: 'SHOW_DRAWER'; drawerConfig: DrawerProps<ID>['drawerConfig'] }
  | {
      type: 'UPDATE_DRAWER_CONTENT';
      contentProps: DrawerProps<ID>['drawerConfig']['contentProps'];
    }
  | { type: 'UPDATE_DRAWER_TITLE'; title: string }
  | { type: 'HIDE_DRAWER' };

interface DrawerContextStore<ID extends DRAWER_IDS> {
  drawerConfig: FormikDrawerConfig<ID> | NonFormikDrawerConfig<ID>;
  dispatch: Dispatch<Action<ID>>;
  isDrawerOpen: boolean;
}

const initialDrawerConfig = {
  id: DRAWER_IDS.NO_DRAWER,
  title: '',
  isFormikDrawer: false,
  contentProps: {},
  instanceId: Symbol(),
} as NonFormikDrawerConfig<DRAWER_IDS>;

const initialState: DrawerContextStore<DRAWER_IDS> = {
  drawerConfig: initialDrawerConfig,
  dispatch: () => {
    /* intentionally empty */
  },
  isDrawerOpen: false,
};

export const DrawerContext = createContext(initialState);

type ShowDrawerProps<ID extends DRAWER_IDS> =
  | Omit<FormikDrawerConfig<ID>, 'instanceId'>
  | Omit<NonFormikDrawerConfig<ID>, 'instanceId'>;

export const useDrawer = () => {
  const { drawerConfig: activeDrawerConfig, dispatch } =
    useContext(DrawerContext);
  const instanceId = useRef(Symbol());

  const showDrawer = useCallback(
    <ID extends DRAWER_IDS>(props: ShowDrawerProps<ID>) => {
      const { id, title, isFormikDrawer, contentProps } = props;

      if (isFormikDrawer && props.formikProps) {
        const formikDrawerConfig = {
          id,
          title,
          contentProps,
          isFormikDrawer,
          formikProps: props.formikProps,
          instanceId: instanceId.current,
        } as FormikDrawerConfig<ID>;

        dispatch({
          type: 'SHOW_DRAWER',
          drawerConfig: formikDrawerConfig,
        });
      } else {
        const nonFormikDrawerConfig = {
          id,
          title,
          contentProps,
          isFormikDrawer,
          formikProps: null,
          instanceId: instanceId.current,
        } as NonFormikDrawerConfig<ID>;

        dispatch({
          type: 'SHOW_DRAWER',
          drawerConfig: nonFormikDrawerConfig,
        });
      }
    },
    [dispatch],
  );

  const hideDrawer = useCallback(() => {
    dispatch({ type: 'HIDE_DRAWER' });
  }, [dispatch]);

  const updateDrawerContent = useCallback(
    <ID extends DRAWER_IDS>(
      contentProps: ShowDrawerProps<ID>['contentProps'],
    ) => {
      if (
        activeDrawerConfig?.id === DRAWER_IDS.NO_DRAWER ||
        instanceId.current !== activeDrawerConfig?.instanceId
      )
        return;

      if (!isEqual(contentProps, activeDrawerConfig.contentProps)) {
        dispatch({
          type: 'UPDATE_DRAWER_CONTENT',
          contentProps,
        });
      }
    },
    [dispatch, activeDrawerConfig],
  );

  const updateDrawerTitle = useCallback(
    (title: string) => {
      if (
        activeDrawerConfig?.id === DRAWER_IDS.NO_DRAWER ||
        instanceId.current !== activeDrawerConfig?.instanceId
      )
        return;

      if (!isEqual(title, activeDrawerConfig.title)) {
        dispatch({
          type: 'UPDATE_DRAWER_TITLE',
          title,
        });
      }
    },
    [dispatch, activeDrawerConfig],
  );

  return { showDrawer, hideDrawer, updateDrawerContent, updateDrawerTitle };
};

const drawerReducer = <ID extends DRAWER_IDS>(
  drawerConfig: DrawerProps<ID>['drawerConfig'],
  action: Action<ID>,
) => {
  switch (action.type) {
    case 'SHOW_DRAWER': {
      const { drawerConfig } = action;
      return drawerConfig;
    }
    case 'UPDATE_DRAWER_CONTENT': {
      const { contentProps } = action;
      return {
        ...drawerConfig,
        contentProps,
      };
    }
    case 'UPDATE_DRAWER_TITLE': {
      const { title } = action;
      return {
        ...drawerConfig,
        title,
      };
    }
    case 'HIDE_DRAWER':
      return initialState.drawerConfig;
    default:
      throw new Error(`Provided action type ${action} is not valid.`);
  }
};

interface DrawerProviderProps {
  children?: ReactNode;
}

export const DrawerProvider = ({ children }: DrawerProviderProps) => {
  const [drawerConfig, dispatch] = useReducer(
    drawerReducer,
    initialState.drawerConfig,
  );

  const value = useMemo(
    () => ({
      drawerConfig,
      dispatch,
      isDrawerOpen: drawerConfig.id !== DRAWER_IDS.NO_DRAWER,
    }),
    [drawerConfig, dispatch],
  );

  useEffect(() => {
    // close drawer on window history change (back button)
    const handleRouteChange = () => {
      if (drawerConfig) {
        dispatch({ type: 'HIDE_DRAWER' });
      }
    };
    window.addEventListener('popstate', handleRouteChange);
    return () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, [drawerConfig]);

  const hideDrawer = useCallback(() => {
    dispatch({ type: 'HIDE_DRAWER' });
  }, [dispatch]);

  return (
    <DrawerContext.Provider value={value}>
      {children}
      <DrawerPlaceholder hideDrawer={hideDrawer} />
    </DrawerContext.Provider>
  );
};

const DrawerPlaceholder = ({ hideDrawer }: { hideDrawer: () => void }) => {
  const { drawerConfig } = useContext(DrawerContext);

  if (!drawerConfig) return <></>;

  return <Drawer drawerConfig={drawerConfig} hideDrawer={hideDrawer} />;
};
