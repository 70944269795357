import { Container, ContainerProps, CircularProgress } from '@mui/material';

interface SpinnerProps {
  sx?: ContainerProps['sx'];
  size?: number;
}

const Spinner = (props: SpinnerProps) => {
  return (
    <Container
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...props.sx,
      }}
    >
      <CircularProgress color='neutral' size={props.size ?? 20} />
    </Container>
  );
};

export default Spinner;
