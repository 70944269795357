import { createSlice } from '@reduxjs/toolkit';
import { ChatThread } from '../../generated/types';

type ChatThreadForStore = ChatThread & { deleted?: boolean };

type InProgressChatThread = {
  id: string;
  responseId: string;
  isCompleted: boolean;
};

interface ChatThreadsState {
  chatThreads?: ChatThreadForStore[];
  inProgressChatThreads: InProgressChatThread[];
  selectedChatThreadId?: string; // user selected chat thread
  isSelectedChatThreadLoaded: boolean;
}

const initialStateValue: ChatThreadsState = {
  chatThreads: undefined,
  inProgressChatThreads: [],
  selectedChatThreadId: undefined,
  isSelectedChatThreadLoaded: false,
};

export const ChatThreadsSlice = createSlice({
  name: 'chatThreads',
  initialState: { value: initialStateValue },
  reducers: {
    setChatThreads: (state, action) => {
      state.value.chatThreads = action.payload;
    },
    addChatThread: (state, action) => {
      // add it to the beginning of the array
      state.value.chatThreads = [
        action.payload,
        ...(state.value.chatThreads ?? []),
      ];
    },
    updateChatThreadTitle: (state, action) => {
      const { id, title } = action.payload;
      const thread = state.value.chatThreads?.find(
        (thread) => thread.id === id,
      );
      if (thread) {
        thread.title = title;
      }
    },
    deleteChatThread: (state, action) => {
      const { id } = action.payload;
      // tag deleted true to the deleted thread
      // This avoids updating entire chatThreads
      // so it avoids re-initializing the whole chat
      const thread = state.value.chatThreads?.find(
        (thread) => thread.id === id,
      );
      if (thread) {
        thread.deleted = true;
      }
    },
    setIsSelectedChatThreadLoaded: (state, action) => {
      state.value.isSelectedChatThreadLoaded = action.payload;
    },
    addInProgressChatThreadById: (state, action) => {
      // check if the thread is already in the array
      const { chatThreadId, chatResponseId } = action.payload;
      const threadIndex = state.value.inProgressChatThreads.findIndex(
        (thread) => thread.id === chatThreadId,
      );
      if (threadIndex < 0) {
        state.value.inProgressChatThreads.push({
          id: chatThreadId,
          responseId: chatResponseId,
          isCompleted: false,
        });
      } else {
        // if it is already in the array, update the responseId and isCompleted
        state.value.inProgressChatThreads[threadIndex].responseId =
          chatResponseId;
        state.value.inProgressChatThreads[threadIndex].isCompleted;
      }
    },
    completeInProgressChatThreadById: (state, action) => {
      const threadId = action.payload;
      const threadIndex = state.value.inProgressChatThreads.findIndex(
        (thread) => thread.id === threadId,
      );
      if (threadIndex >= 0) {
        state.value.inProgressChatThreads[threadIndex].isCompleted = true;
      }
    },
    removeInProgressChatThreadById: (state, action) => {
      const threadId = action.payload;
      const threadIndex = state.value.inProgressChatThreads.findIndex(
        (thread) => thread.id === threadId,
      );
      if (threadIndex >= 0) {
        state.value.inProgressChatThreads.splice(threadIndex, 1);
      }
    },
    setSelectedChatThreadId: (state, action) => {
      state.value.selectedChatThreadId = action.payload;
    },
    resetChatThreads: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const {
  setChatThreads,
  addChatThread,
  updateChatThreadTitle,
  deleteChatThread,
  setIsSelectedChatThreadLoaded,
  addInProgressChatThreadById,
  completeInProgressChatThreadById,
  removeInProgressChatThreadById,
  setSelectedChatThreadId,
  resetChatThreads,
} = ChatThreadsSlice.actions;

export default ChatThreadsSlice.reducer;
