import { ChipOptionType } from '../components/shared/ToggleChips';
import { ChatConfig, DEFAULT_MODEL } from '../store/slices/workspace';

// temperature and vizGenerationMode are missing from the UI
export const CHAT_SETTING_VALUES = {
  MODEL: DEFAULT_MODEL,
  QUICK_FOLLOW: 'quick-follow',
  COMMON_QUESTION_HINTS: 'common-question-hints',
  DATA_EMBEDDINGS: 'data-embeddings',
  QUESTION_EMBEDDINGS: 'question-embeddings',
};

type ChatSettingOption = ChipOptionType & {
  configKey?: keyof ChatConfig;
};

export const chatSettingOptions: ChatSettingOption[] = [
  {
    label: 'Model',
    value: CHAT_SETTING_VALUES.MODEL,
    configKey: 'model',
  },
  {
    label: 'Quick-follow',
    value: CHAT_SETTING_VALUES.QUICK_FOLLOW,
    configKey: 'enableQuickFollow',
  },
  {
    label: 'Common question hints',
    value: CHAT_SETTING_VALUES.COMMON_QUESTION_HINTS,
    configKey: 'enableCommonQuestionHints',
  },
  {
    label: 'Data embeddings',
    value: CHAT_SETTING_VALUES.DATA_EMBEDDINGS,
    configKey: 'enableEmbeddings',
  },
  {
    label: 'Question embeddings',
    value: CHAT_SETTING_VALUES.QUESTION_EMBEDDINGS,
    configKey: 'enableQuestionEmbeddings',
  },
];

export const getSelectedChatSetting = (chatConfig: ChatConfig) => {
  return chatSettingOptions
    .filter((option) => {
      if (option.configKey === 'model') {
        return true;
      }
      return chatConfig[option.configKey as keyof ChatConfig];
    })
    .map((option) => {
      if (option.configKey === 'model') {
        return chatConfig.model;
      }
      return option.value;
    });
};
