import { useState, useEffect, useContext } from 'react';
import { useRouter } from 'next/router';
import PageError from '../PageError';
import LoadingScreen from '../shared/LoadingScreen';
import WorkspaceLayout from './WorkspaceLayout';
import ErrorBoundary from '../ErrorBoundary';
import PoweredByRibbon from '../PoweredBy/Ribbon';
import { EnvironmentContext } from '../EnvironmentProvider';

interface EmbedLayoutProps {
  children: React.ReactNode;
}

// Embedded app always renders the WorkspaceLayout
const EmbedLayout = ({ children }: EmbedLayoutProps) => {
  const router = useRouter();
  const routerWorkspaceId = router.query.workspaceId as string;
  const { environment } = useContext(EnvironmentContext);
  const shouldShowPoweredBy = !environment?.whiteLabeled;

  // Show loading state for 6s before error page when workspaceId is missing
  // This allows time for the hosting app to generate and pass the ID
  const [embeddedLoading, setEmbeddedLoading] = useState(true);
  useEffect(() => {
    if (routerWorkspaceId !== undefined) {
      setEmbeddedLoading(false);
      return;
    }
    const timer = setTimeout(() => setEmbeddedLoading(false), 6000);
    return () => clearTimeout(timer);
  }, [routerWorkspaceId]);

  if (embeddedLoading) {
    return <LoadingScreen />;
  }

  if (!routerWorkspaceId) {
    return (
      <PageError
        title='Embedded app misconfiguration'
        message='Please try refreshing the page. If the issue persists, please contact your administrator for assistance.'
      />
    );
  }

  return (
    <ErrorBoundary>
      <WorkspaceLayout workspaceId={routerWorkspaceId}>
        {children}
        {shouldShowPoweredBy && <PoweredByRibbon />}
      </WorkspaceLayout>
    </ErrorBoundary>
  );
};

export default EmbedLayout;
