import { useCallback } from 'react';
import { Field, Form, Formik } from 'formik';
import FormFieldLabel from '../shared/Form/FormFieldLabel';
import { Stack, TextField } from '@mui/material';
import * as Yup from 'yup';

import Alert from '../shared/Alert';
import DialogActions from '../shared/Dialog/DialogActions';
import {
  useDeleteEmbedAuthorizationMutation,
  EnvironmentEmbedAuthorizationsDocument,
} from '../../generated/types';
import { BaseRegisteredDialogComponentProps } from './types';

export interface DeleteEmbedTokenDialogProps
  extends BaseRegisteredDialogComponentProps {
  embedAuthId: string;
}

const initialFormValues = {
  confirmPhrase: '',
};

const validationSchema = Yup.object({
  confirmPhrase: Yup.string()
    .matches(/^UNDERSTOOD$/, 'Please type UNDERSTOOD to confirm')
    .required('Please type UNDERSTOOD to continue'),
});

const DeleteEmbedTokenDialog = ({
  embedAuthId,
  hideDialog,
}: DeleteEmbedTokenDialogProps) => {
  const [deleteToken] = useDeleteEmbedAuthorizationMutation({
    refetchQueries: [EnvironmentEmbedAuthorizationsDocument],
  });

  const handleFormSubmit = useCallback(async () => {
    await deleteToken({
      variables: {
        input: {
          embedAuthId,
        },
      },
    });
  }, [deleteToken, embedAuthId]);

  return (
    <Formik
      initialValues={initialFormValues}
      onSubmit={handleFormSubmit}
      validationSchema={validationSchema}
    >
      {({ touched, errors, isValid, dirty }) => (
        <Form>
          <Stack spacing={4} mx={2} my={1}>
            <Alert severity='warning' sx={{ width: '100%' }}>
              Deleting the token will permanently remove it and will remove your
              embedded app&apos;s access.
            </Alert>
            <Stack spacing={1}>
              <FormFieldLabel text='Type "UNDERSTOOD" to confirm' required />
              <Field
                as={TextField}
                name='confirmPhrase'
                size='small'
                fullWidth
                required
                error={touched.confirmPhrase && Boolean(errors.confirmPhrase)}
                helperText={touched.confirmPhrase && errors.confirmPhrase}
              />
            </Stack>
          </Stack>
          <Stack>
            <DialogActions
              isBuiltIn
              closeDialog={hideDialog}
              secondaryAction={{
                text: 'Cancel',
                action: hideDialog,
                disabled: false,
              }}
              primaryAction={{
                text: 'Delete',
                action: () => handleFormSubmit(),
                disabled: !isValid || !dirty,
              }}
            />
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default DeleteEmbedTokenDialog;
