import { useCallback, useContext, useRef } from 'react';
import {
  DIALOG_IDS,
  DialogComponentPropsMapping,
} from '../components/registeredDialogs/dialogRegistry';

import {
  DialogContext,
  DialogConfig,
  DialogActionType,
} from '../contexts/DialogProvider';

type ShowDialogProps<ID extends DIALOG_IDS> = {
  id: ID;
  title: string;
  fullDisplay?: boolean;
  contentProps: Omit<DialogComponentPropsMapping[ID], 'hideDialog'>;
  primaryAction?: DialogActionType;
  secondaryAction?: DialogActionType;
};

export const useDialog = () => {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error('useDialog must be used within a DialogProvider');
  }
  const { dispatch } = context;
  const instanceId = useRef(Symbol());

  const showDialog = useCallback(
    <ID extends DIALOG_IDS>(props: ShowDialogProps<ID>) => {
      const {
        id,
        title,
        fullDisplay,
        contentProps,
        primaryAction,
        secondaryAction,
      } = props;
      const dialog: DialogConfig<ID> = {
        id,
        title,
        fullDisplay,
        contentProps,
        primaryAction,
        secondaryAction,
        instanceId: instanceId.current,
      };
      dispatch({ type: 'SHOW_DIALOG', dialog });
    },
    [dispatch],
  );

  const updateDialogContentProp = useCallback(
    <ID extends DIALOG_IDS>(
      id: ID,
      contentProps: Omit<DialogComponentPropsMapping[ID], 'hideDialog'>,
    ) => {
      dispatch({ type: 'UPDATE_DIALOG_CONTENT', id, contentProps });
    },
    [dispatch],
  );

  const hideDialog = useCallback(
    (id: DIALOG_IDS) => {
      dispatch({ type: 'HIDE_DIALOG', id });
    },
    [dispatch],
  );

  return {
    showDialog,
    hideDialog,
    updateDialogContentProp,
  };
};
