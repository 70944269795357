import { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';

// Components
import { Stack } from '@mui/material';
import ErrorBoundary from '../ErrorBoundary';
import WorkspaceLayout from './WorkspaceLayout';
import EmptyState from '../shared/EmptyState';
import SettingsTopBar from './LayoutComponents/SettingsTopBar';
import WorkspaceSelectorBar from '../WorkspaceSelectorBar';
import SettingsSidebar from './LayoutComponents/SettingsSidebar';

// Hooks and Utils
import { useSessionInfo } from '../../hooks';
import { getWorkspaceNavigationUrl } from '../../utils/getWorkspaceNavigationUrl';
import {
  isWorkspaceMainPage,
  isWorkspaceRelatedPage,
} from '../../utils/isWorkspacePage';

interface WebAppLayoutProps {
  children: React.ReactNode;
}

const WebAppLayout = ({ children }: WebAppLayoutProps) => {
  const router = useRouter();
  const pathname = router.pathname;
  const { isWorkspacePage, requireWorkspace } = useMemo(
    () => ({
      isWorkspacePage: isWorkspaceMainPage(router),
      requireWorkspace: isWorkspaceRelatedPage(router),
    }),
    [router],
  );

  const workspaceId = router.query.workspaceId as string;

  const { userType } = useSessionInfo();
  const showC1Settings =
    userType === 'EnvironmentMember' || userType === 'SystemUser';

  const workspaceSelectionCallback = useCallback(
    (workspaceId: string) => {
      const newUrl = getWorkspaceNavigationUrl(router.pathname, workspaceId);
      router.push(newUrl, undefined, { shallow: true });
    },
    [router],
  );

  const showEmptyState = requireWorkspace && !workspaceId;

  return (
    <ErrorBoundary>
      <Stack id='top-nav-content-container' flex={1} overflow='hidden'>
        {showC1Settings && <SettingsTopBar workspaceId={workspaceId} />}
        {showC1Settings && requireWorkspace && (
          <WorkspaceSelectorBar
            selectionCallback={workspaceSelectionCallback}
            workspaceId={workspaceId}
          />
        )}

        {showEmptyState && (
          <EmptyState
            title='No workspace selected'
            subTitle='Choose a workspace from the list to get started.'
          />
        )}
        {!showEmptyState && (
          <Stack
            id='left-nav-content-container'
            direction='row'
            flex={1}
            width='100%'
            overflow='hidden'
          >
            <SettingsSidebar
              currentPathPattern={pathname}
              workspaceId={workspaceId}
            />
            <Stack id='page-content' width='100%' overflow='auto' flex={1}>
              {isWorkspacePage && workspaceId ? (
                <WorkspaceLayout workspaceId={workspaceId}>
                  {children}
                </WorkspaceLayout>
              ) : (
                children
              )}
            </Stack>
          </Stack>
        )}
      </Stack>
    </ErrorBoundary>
  );
};

export default WebAppLayout;
