import { Form, Formik } from 'formik';
import { Stack, Typography } from '@mui/material';
import TextField from '../shared/Inputs/TextField';
import DialogActions from '../shared/Dialog/DialogActions';
import { BaseRegisteredDialogComponentProps } from './types';

export interface RenameDialogProps extends BaseRegisteredDialogComponentProps {
  defaultName: string;
  handleNameChange: (values: RenameFormValues) => void;
}

type RenameFormValues = {
  name: string;
};

const RenameDialog = ({
  defaultName,
  hideDialog,
  handleNameChange,
}: RenameDialogProps) => {
  return (
    <Formik
      initialValues={{ name: defaultName }}
      onSubmit={handleNameChange}
      submitOnEnter={false}
    >
      {({ values, dirty, setFieldValue }) => (
        <Form>
          <Stack spacing={3}>
            <Typography>
              Choose a clear and descriptive title for <b>{defaultName}</b>.
            </Typography>
            <TextField
              label='Title'
              value={values.name}
              onChange={(e) => setFieldValue('name', e.target.value)}
            />
          </Stack>
          <DialogActions
            closeDialog={hideDialog}
            secondaryAction={{
              text: 'Cancel',
              action: hideDialog,
              disabled: false,
            }}
            primaryAction={{
              text: 'Rename',
              action: () => handleNameChange(values),
              disabled: !dirty || values.name === '',
            }}
          />
        </Form>
      )}
    </Formik>
  );
};

export default RenameDialog;
