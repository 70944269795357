import { useCallback } from 'react';
import { useRouter } from 'next/router';
import {
  // Queries
  useEnvironmentByIdLazyQuery,
  useEnvironmentExposedLookerDataModelExploresLazyQuery,
  useEnvironmentWorkspacesLazyQuery,
  useViewerLazyQuery,

  // Workspace Mutations
  useCreateWorkspaceMutation,
  useCreateWorkspaceLookerDataModelExploreMutation,
  useDeleteWorkspaceMutation,

  // Environment Mutations
  useEditEnvironmentMutation,

  // Environment Explore Mutations
  useCreateEnvironmentLookerDataModelExploreMutation,
  useUpdateEnvironmentLookerDataModelExploreMutation,
  useDeleteEnvironmentLookerDataModelExploreMutation,

  // Input Types
  CreateWorkspaceInput,
  DeleteWorkspaceInput,
  EditEnvironmentInput,
  CreateEnvironmentLookerDataModelExploreInput,
  UpdateEnvironmentLookerDataModelExploreInput,
  DeleteEnvironmentLookerDataModelExploreInput,

  // Entity Types
  Environment,
  EnvironmentLookerDataModelExplore,
  PolicyEdge,
  Workspace,

  // Documents
  EnvironmentWorkspacesDocument,
} from '../generated/types';
import { useAppDispatch, useAppSelector, useSessionInfo } from '../hooks';
import { covertGQLPolicyToReduxPolicy } from './useEnvironmentPolicies';
import {
  setEnvironment,
  setAgentPersonaConfig,
  setPoliciesAction,
  setIsLoading,
  setEnvExplores,
  addEnvExplore,
  updateEnvExplore as updateEnvExploreAction,
  removeEnvExplore,
  Policy,
  setEnvWorkspaceOptions,
  setDefaultEmbedAuthId,
} from '../store/slices/environment';

import { useEmbeddingContext } from '../contexts/EmbeddingProvider';
import { setCustomBranding } from '../store/slices/session';
import { useEnvironmentAgentProfile } from './useEnvironmentAgentProfile';
import { AgentPersona } from '@madeinventive/core-types';
import { useActionToast } from './useActionToast';

export const useEnvironment = () => {
  const router = useRouter();
  const { isEmbedded } = useEmbeddingContext();
  const { environmentId: userEnvironmentId, userType } = useSessionInfo();
  const { executeMutationWithToast } = useActionToast();
  const { handleReceiveAgentPersona } = useEnvironmentAgentProfile();
  const { environment, envExplores, envWorkspaceOptions, defaultEmbedAuthId } =
    useAppSelector((store) => store.environment.value);
  const isSystemOrC1User =
    userType === 'EnvironmentMember' || userType === 'SystemUser';
  const canModifyEnvironment = !isEmbedded && isSystemOrC1User;

  const storeDispatch = useAppDispatch();

  // environment
  const [fetchEnvByUser, { loading, error }] = useViewerLazyQuery();
  const [fetchEnvById] = useEnvironmentByIdLazyQuery();

  // environment embed auth
  const [editEnvironment] = useEditEnvironmentMutation();

  const setDefaultEmbedAuth = useCallback(
    async (embedAuthId: string) => {
      if (!environment || embedAuthId === defaultEmbedAuthId) return;
      const result = await editEnvironment({
        variables: {
          input: {
            id: environment.id,
            fields: {
              deepLinkEmbedAuthId: embedAuthId,
            },
          },
        },
      });

      if (result.data?.editEnvironment.environment.deepLinkAuthorization?.id) {
        storeDispatch(
          setDefaultEmbedAuthId(
            result.data.editEnvironment.environment.deepLinkAuthorization.id,
          ),
        );
      }
    },
    [environment, defaultEmbedAuthId, editEnvironment, storeDispatch],
  );

  const updateBranding = useCallback(
    async (input: EditEnvironmentInput) => {
      executeMutationWithToast(
        () => editEnvironment({ variables: { input } }),
        'editEnvironment',
        'Changes saved.',
        'Failed to save changes.',
        (data) => {
          const branding = {
            logoUrl: data?.editEnvironment.environment.logoUrl ?? '',
            themeColor: data?.editEnvironment.environment.themeColor ?? '',
            iconUrl: data?.editEnvironment.environment.iconUrl ?? '',
          };
          storeDispatch(setCustomBranding(branding));
        },
      );
    },
    [editEnvironment, executeMutationWithToast, storeDispatch],
  );

  // env workspaces
  const [fetchWorkspaces, { loading: envWorkspaceLoading }] =
    useEnvironmentWorkspacesLazyQuery();

  // env explores
  const [
    fetchEnvironmentExposedDataModels,
    { loading: envExploreLoading, error: envExploreError },
  ] = useEnvironmentExposedLookerDataModelExploresLazyQuery();
  const [createEnvironmentLookerDataModelExplore] =
    useCreateEnvironmentLookerDataModelExploreMutation();
  const [updateEnvironmentLookerDataModelExplore] =
    useUpdateEnvironmentLookerDataModelExploreMutation();
  const [deleteEnvironmentLookerDataModelExplore] =
    useDeleteEnvironmentLookerDataModelExploreMutation();

  const loadWorkspaces = useCallback(
    async (environmentId: string) => {
      const result = await fetchWorkspaces({
        variables: {
          environmentId: environmentId,
        },
      });

      if (result?.data?.node?.__typename === 'Environment') {
        const options = result.data.node.workspaces.edges
          .filter((edge) => edge?.node?.name && edge?.node?.id)
          .map((edge) => ({
            label: edge.node?.name ?? '',
            id: edge.node?.id ?? '',
          }));
        storeDispatch(setEnvWorkspaceOptions(options));
      }
    },
    [fetchWorkspaces, storeDispatch],
  );

  const fetchEnvExplores = useCallback(
    async (environmentId?: string) => {
      if (!canModifyEnvironment) return;
      if (!userEnvironmentId && !environmentId) return;

      const result = await fetchEnvironmentExposedDataModels({
        variables: {
          environmentId: (userEnvironmentId || environmentId) ?? '',
        },
      });

      const envExposedModels = result?.data;

      if (envExposedModels) {
        const models =
          envExposedModels?.node?.__typename === 'Environment' &&
          envExposedModels?.node?.exposedLookerDataModelExplores
            ? envExposedModels.node.exposedLookerDataModelExplores.map(
                (explore) => explore as EnvironmentLookerDataModelExplore,
              )
            : undefined;
        if (models) {
          storeDispatch(setEnvExplores(models));
        }
      }
    },
    [
      userEnvironmentId,
      fetchEnvironmentExposedDataModels,
      storeDispatch,
      canModifyEnvironment,
    ],
  );

  const createEnvExplore = useCallback(
    async (input: CreateEnvironmentLookerDataModelExploreInput) => {
      if (!canModifyEnvironment) return;

      try {
        const created = await createEnvironmentLookerDataModelExplore({
          variables: {
            input,
          },
        });

        if (created.data?.createEnvironmentLookerDataModelExplore.explore) {
          const explore = created.data.createEnvironmentLookerDataModelExplore
            .explore as EnvironmentLookerDataModelExplore;
          storeDispatch(addEnvExplore(explore));
        }
      } catch {
        // Nothing to do here just make sure any ApolloError is handled.
        // Showing the error to user is already handled by the error link.
      }
    },
    [
      createEnvironmentLookerDataModelExplore,
      canModifyEnvironment,
      storeDispatch,
    ],
  );

  const updateEnvExplore = useCallback(
    async (input: UpdateEnvironmentLookerDataModelExploreInput) => {
      if (!canModifyEnvironment) return;

      try {
        const updated = await updateEnvironmentLookerDataModelExplore({
          variables: {
            input,
          },
        });

        if (updated.data?.updateEnvironmentLookerDataModelExplore.explore) {
          const explore = updated.data.updateEnvironmentLookerDataModelExplore
            .explore as EnvironmentLookerDataModelExplore;
          storeDispatch(updateEnvExploreAction(explore));
        }
      } catch {
        // Nothing to do here just make sure any ApolloError is handled.
        // Showing the error to user is already handled by the error link.
      }
    },
    [
      updateEnvironmentLookerDataModelExplore,
      canModifyEnvironment,
      storeDispatch,
    ],
  );

  const deleteEnvExplore = useCallback(
    async (input: DeleteEnvironmentLookerDataModelExploreInput) => {
      if (!canModifyEnvironment) return;

      try {
        const deleted = await deleteEnvironmentLookerDataModelExplore({
          variables: {
            input,
          },
        });

        if (deleted) {
          storeDispatch(removeEnvExplore(input.id));
        }
      } catch {
        // Nothing to do here just make sure any ApolloError is handled.
        // Showing the error to user is already handled by the error link.
      }
    },
    [
      deleteEnvironmentLookerDataModelExplore,
      canModifyEnvironment,
      storeDispatch,
    ],
  );

  const loadEnvironmentAndResourcesToStore = useCallback(
    (environment: Environment) => {
      storeDispatch(setEnvironment(environment));
      storeDispatch(
        setDefaultEmbedAuthId(environment.deepLinkAuthorization?.id ?? null),
      );
      if (environment.agentPersonaConfig) {
        storeDispatch(setAgentPersonaConfig(environment.agentPersonaConfig));
      }
      if (environment.policies?.edges) {
        const policies: Policy[] = environment.policies.edges.map(
          (policyEdge: PolicyEdge) => {
            const policy = policyEdge.node;
            const reduxPolicy = covertGQLPolicyToReduxPolicy(policy);
            return reduxPolicy;
          },
        );
        storeDispatch(setPoliciesAction(policies));
      }
      handleReceiveAgentPersona(environment.agentPersonaConfig as AgentPersona);
      storeDispatch(
        setCustomBranding({
          logoUrl: environment.logoUrl || '',
          themeColor: environment.themeColor || '',
          iconUrl: environment.iconUrl || '',
        }),
      );
    },
    [handleReceiveAgentPersona, storeDispatch],
  );

  const handleEnvironment = useCallback(
    (environment: Environment) => {
      if (isSystemOrC1User) {
        loadWorkspaces(environment.id);
      }
      fetchEnvExplores(environment.id);
      loadEnvironmentAndResourcesToStore(environment);
    },
    [
      isSystemOrC1User,
      fetchEnvExplores,
      loadEnvironmentAndResourcesToStore,
      loadWorkspaces,
    ],
  );

  const initializeEnvironmentForNonSystemUser = useCallback(async () => {
    storeDispatch(setIsLoading(true));

    // Inline the fetch logic
    let environment: Environment | undefined;
    const result = await fetchEnvByUser();
    const data = result?.data;
    if (data) {
      if (data?.viewer.user?.__typename === 'EnvironmentMember') {
        environment = data.viewer.user.environment as Environment;
      } else if (data?.viewer.user?.__typename === 'WorkspaceMember') {
        environment = data.viewer.user.workspace.environment as Environment;
      }
    }

    environment && handleEnvironment(environment);
    storeDispatch(setIsLoading(false));
  }, [storeDispatch, fetchEnvByUser, handleEnvironment]);

  const initializeEnvironmentForSystemUser = useCallback(
    async (environmentId: string) => {
      storeDispatch(setIsLoading(true));

      const result = await fetchEnvById({
        variables: {
          environmentId,
        },
      });
      const environment = result?.data?.node as Environment;
      environment && handleEnvironment(environment);
      storeDispatch(setIsLoading(false));
    },
    [storeDispatch, fetchEnvById, handleEnvironment],
  );

  // workspaces
  const [createWorkspaceMutation] = useCreateWorkspaceMutation();
  const [createWorkspaceLookerDataModelExplore] =
    useCreateWorkspaceLookerDataModelExploreMutation();
  const [deleteWS] = useDeleteWorkspaceMutation();

  const createWorkspaceAddExplore = useCallback(
    async (
      input: CreateWorkspaceInput,
      explore?: string,
      customerIdentifiers?: string[],
    ): Promise<Workspace | null> => {
      const effectiveEnvironmentId = userEnvironmentId || input.environmentId;
      try {
        const result = await createWorkspaceMutation({
          variables: {
            input: {
              ...input,
              environmentId: effectiveEnvironmentId,
            },
          },
          // return on this mutation does not include the full workspace object for redux state
          // so we need to refetch the environment from the Viewer Document to get the full workspace object
          refetchQueries: [EnvironmentWorkspacesDocument],
        });
        const data = result?.data;

        if (data && explore) {
          await createWorkspaceLookerDataModelExplore({
            variables: {
              input: {
                workspaceId: data.createWorkspace.workspace.id,
                lookerDataModelExploreId: explore,
                customerFilterValues: customerIdentifiers || [],
              },
            },
          });
          initializeEnvironmentForSystemUser(effectiveEnvironmentId);

          const workspace = data.createWorkspace.workspace;
          if (workspace.__typename === 'Workspace') {
            return workspace as Workspace;
          }
        }
      } catch {
        // Nothing to do here just make sure any ApolloError is handled.
        // Showing the error to user is already handled by the error link.
      }

      return null;
    },
    [
      userEnvironmentId,
      initializeEnvironmentForSystemUser,
      createWorkspaceMutation,
      createWorkspaceLookerDataModelExplore,
    ],
  );

  const deleteWorkspaceFromEnvironment = useCallback(
    async (input: DeleteWorkspaceInput) => {
      executeMutationWithToast(
        () => deleteWS({ variables: { input } }),
        'deleteWorkspace',
        'Workspace deleted successfully',
        'Failed to delete workspace',
        () => router.push('/environment'),
      );
    },
    [deleteWS, executeMutationWithToast, router],
  );

  return {
    // requests
    initializeEnvironmentForNonSystemUser,
    initializeEnvironmentForSystemUser,
    fetchEnvExplores,
    createEnvExplore,
    updateEnvExplore,
    deleteEnvExplore,
    createWorkspaceAddExplore,
    deleteWorkspaceFromEnvironment,
    setDefaultEmbedAuth,
    updateBranding,

    // states
    loading,
    error,
    envExploreLoading,
    envExploreError,
    envWorkspaceLoading,

    // slice values
    environment,
    envExplores,
    envWorkspaceOptions,
    defaultEmbedAuthId,
  };
};
