import { useCallback } from 'react';
import {
  CreatePolicyInput,
  EditPolicyInput,
  Policy as GQLPolicy,
  useCreateEnvironmentPolicyMutation,
  useDeleteEnvironmentPolicyMutation,
  useEditEnvironmentPolicyMutation,
} from '../generated/types';
import { useAppSelector, useAppDispatch } from './store';
import {
  Policy as ReduxPolicy,
  addPolicyAction,
  editPolicyAction,
  deletePolicyAction,
} from '../store/slices/environment';
import { useActionToast } from './useActionToast';

export const covertGQLPolicyToReduxPolicy = (
  policy: GQLPolicy,
): ReduxPolicy => {
  const createdBy = policy.createdBy;
  const reduxPolicy: ReduxPolicy = {
    id: policy.id,
    createdBy: createdBy ? `${createdBy.firstname} ${createdBy.lastname}` : '',
    lastUpdated: policy.updatedAt,
    context: policy.config.context,
    response: policy.config.response,
    constraint: policy.config.constraint,
  };
  return reduxPolicy;
};

const useEnvironmentPolicies = () => {
  const storeDispatch = useAppDispatch();
  const { executeMutationWithToast } = useActionToast();
  const { policies, environment, isLoading } = useAppSelector(
    (state) => state.environment.value,
  );
  const [createPolicy] = useCreateEnvironmentPolicyMutation();
  const [editPolicy] = useEditEnvironmentPolicyMutation();
  const [deletePolicy] = useDeleteEnvironmentPolicyMutation();

  const createEnvPolicy = useCallback(
    async (values: {
      context: string;
      response: string;
      constraint: string;
    }) => {
      if (!environment?.id) {
        return;
      }

      const input: CreatePolicyInput = {
        environmentId: environment?.id,
        policyConfig: {
          context: values.context,
          response: values.response,
          constraint: values.constraint,
        },
      };

      executeMutationWithToast(
        () => createPolicy({ variables: { input } }),
        'createPolicy',
        'Policy created.',
        'Failed to create policy.',
        (data) => {
          const newPolicy = covertGQLPolicyToReduxPolicy(
            data?.createPolicy.policy as GQLPolicy,
          );
          storeDispatch(addPolicyAction(newPolicy));
        },
      );
    },
    [createPolicy, environment?.id, executeMutationWithToast, storeDispatch],
  );

  const editEnvPolicy = useCallback(
    async (
      policyId: string,
      values: {
        context: string;
        response: string;
        constraint: string;
      },
    ) => {
      const input: EditPolicyInput = {
        id: policyId,
        policyConfig: {
          context: values.context,
          response: values.response,
          constraint: values.constraint,
        },
      };
      executeMutationWithToast(
        () => editPolicy({ variables: { input } }),
        'editPolicy',
        'Policy updated.',
        'Failed to update policy.',
        (data) => {
          const updatedPolicy = covertGQLPolicyToReduxPolicy(
            data?.editPolicy.policy as GQLPolicy,
          );
          storeDispatch(editPolicyAction(updatedPolicy));
        },
      );
    },
    [editPolicy, executeMutationWithToast, storeDispatch],
  );

  const deleteEnvPolicy = useCallback(
    async (policyId: string) => {
      executeMutationWithToast(
        () => deletePolicy({ variables: { input: { id: policyId } } }),
        'deletePolicy',
        'Policy deleted.',
        'Policy not deleted. Please retry.',
        () => {
          storeDispatch(deletePolicyAction(policyId));
        },
      );
    },
    [executeMutationWithToast, deletePolicy, storeDispatch],
  );

  return {
    policies,
    createEnvPolicy,
    editEnvPolicy,
    deleteEnvPolicy,
    isLoadingPolicies: isLoading,
  };
};

export default useEnvironmentPolicies;
