import { useCallback } from 'react';
import {
  FeatureState,
  FeedbackRating,
  RatingReasonsInput,
  useRefreshComponentSnapshotMutation,
  useWorkspaceComponentLazyQuery,
  useWorkspaceComponentReferencesLazyQuery,
  useRateComponentInsightMutation,
} from '../generated/types';

export type UseWorkspaceComponentReturnType = ReturnType<
  typeof useWorkspaceComponent
>;

export const useWorkspaceComponent = () => {
  const [fetchWorkspaceComponent, { loading, error }] =
    useWorkspaceComponentLazyQuery();
  const [fetchWorkspaceComponentReferences, { data: componentReferencesData }] =
    useWorkspaceComponentReferencesLazyQuery();

  const [
    refreshWorkspaceComponent,
    { loading: refreshing, error: refreshError },
  ] = useRefreshComponentSnapshotMutation();

  const [rateComponentInsight, { loading: ratingLoading, error: ratingError }] =
    useRateComponentInsightMutation();

  const fetchComponent = useCallback(
    async ({
      workspaceId,
      componentId,
    }: {
      workspaceId: string;
      componentId: string;
    }) => {
      return fetchWorkspaceComponent({
        variables: {
          workspaceId,
          componentId,
        },
      });
    },
    [fetchWorkspaceComponent],
  );

  const fetchComponentReferences = useCallback(
    async ({
      workspaceId,
      componentId,
      state,
    }: {
      workspaceId: string;
      componentId: string;
      state?: FeatureState;
    }) => {
      const result = await fetchWorkspaceComponentReferences({
        variables: {
          workspaceId,
          componentId,
          state,
        },
      });

      return result;
    },
    [fetchWorkspaceComponentReferences],
  );

  const refreshComponent = useCallback(
    async ({ componentId }: { componentId: string }) => {
      return refreshWorkspaceComponent({
        variables: {
          input: {
            componentId,
          },
        },
      });
    },
    [refreshWorkspaceComponent],
  );

  const rateInsight = useCallback(
    async ({
      componentId,
      workspaceId,
      insight,
      insightDate,
      rating,
      reasons,
    }: {
      componentId: string;
      workspaceId: string;
      insight: string;
      insightDate: Date;
      rating: FeedbackRating;
      reasons?: RatingReasonsInput;
    }) => {
      return rateComponentInsight({
        variables: {
          input: {
            componentId,
            workspaceId,
            insight,
            insightDate,
            rating,
            ratingReasons: reasons
              ? {
                  tags: reasons.tags ?? null,
                  summary: reasons.summary ?? null,
                  comment: reasons.comment ?? null,
                }
              : undefined,
          },
        },
      });
    },
    [rateComponentInsight],
  );

  return {
    loading,
    error,
    fetchComponent,
    componentReferencesData,
    fetchComponentReferences,
    refreshing,
    refreshError,
    refreshComponent,
    ratingLoading,
    ratingError,
    rateInsight,
  };
};
