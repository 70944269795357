import { useState, useCallback, useMemo } from 'react';
import { Stack } from '@mui/material';
import { FormikValues } from 'formik';

// components
import Stepper from '../../shared/SteppedForm/Stepper';
import SteppedFormLayout, {
  PrimaryButtonConfig,
} from '../../shared/SteppedForm/SteppedFormLayout';
import InsightsForm from './InsightsForm';
import ActionsForm from './ActionsForm';
import SummaryForm from './SummaryForm';

// types
import { MutableStackComponentProps } from '@madeinventive/core-types';
import { BaseRegisteredDialogComponentProps } from '../types';
import {
  insightsInitialValues,
  insightsValidationSchema,
  actionsInitialValues,
  actionsValidationSchema,
  summaryInitialValues,
  summaryValidationSchema,
  MultiStepFormValues,
  InsightsFormValues,
  ActionsFormValues,
  SummaryFormValues,
} from './types';
import { StackComponentType } from '../../../pages/create/stacks';

// hooks
import { useAppSelector, useEnvironmentComponents } from '../../../hooks';
import {
  EditStackComponentInput,
  ComponentType,
  CreateStackFromDataComponentInput,
} from '../../../generated/types';

export interface CreateOrEditStackDialogProps
  extends BaseRegisteredDialogComponentProps {
  environmentId: string;
  stack?: StackComponentType; // Optional because this is only passed in when editing a stack.
  dataComponentId?: string; // When creating a stack from a query.
}

const steps = ['Insights', 'Actions', 'Summary'];
const ACTIONS_STEP_INDEX = 1;
const SUMMARY_STEP_INDEX = 2;

type StepActionsType = {
  insights: {
    primary: PrimaryButtonConfig;
  };
  actions: {
    primary: PrimaryButtonConfig;
  };
  summary: {
    primary: PrimaryButtonConfig;
  };
};

const CreateOrEditStackDialog = ({
  stack,
  dataComponentId,
  environmentId,
  hideDialog,
}: CreateOrEditStackDialogProps) => {
  const isEditing = !!stack;
  const {
    editStackComponentConfig,
    editStackComponentLoading: editStackLoading,
    createComponentByType,
    createComponentLoading: createStackLoading,
  } = useEnvironmentComponents();

  const baseDataComponent = useAppSelector((state) => {
    return state.environmentComponents?.value[
      environmentId
    ]?.dataComponents?.find((component) => component.id === dataComponentId);
  });

  const [activeStep, setActiveStep] = useState(0);
  const [savedFormValues, setSavedFormValues] = useState<MultiStepFormValues>(
    isEditing
      ? {
          insights: {
            prompt: stack.prompt,
          },
          actions: {
            optInNotifications: stack.enableNotificationOptIn,
            includeActionButton: stack.enableActionButton,
            actionButtonText: stack.actionButtonText,
            actionButtonLink: stack.actionButtonLink,
          },
          summary: {
            stackTitle: stack.stackTitle,
            stackButtonText: stack.stackButtonText,
          },
        }
      : {
          insights: insightsInitialValues,
          actions: actionsInitialValues,
          summary: summaryInitialValues,
        },
  );

  const goBack = useCallback(() => {
    setActiveStep((prev) => prev - 1);
  }, []);

  const continueToActions = useCallback((values: InsightsFormValues) => {
    setSavedFormValues((prev) => ({
      ...prev,
      insights: values,
    }));
    setActiveStep(ACTIONS_STEP_INDEX);
  }, []);

  const continueToSummary = useCallback((values: ActionsFormValues) => {
    setSavedFormValues((prev) => ({
      ...prev,
      actions: values,
    }));
    setActiveStep(SUMMARY_STEP_INDEX);
  }, []);

  const handleCreateStackClick = useCallback(
    async (values: SummaryFormValues) => {
      const multiStepFormValues: MultiStepFormValues = {
        ...savedFormValues,
        summary: values,
      };

      if (!dataComponentId) {
        return;
      }

      const input: CreateStackFromDataComponentInput = {
        dataComponentId,
        name: multiStepFormValues.summary.stackTitle ?? '',
        insightsPrompt: multiStepFormValues.insights.prompt,
        actionConfig: {
          buttonLink: multiStepFormValues.actions.actionButtonLink ?? '',
          buttonText: multiStepFormValues.actions.actionButtonText ?? '',
          enableButton: multiStepFormValues.actions.includeActionButton,
        },
        summaryConfig: {
          buttonText: multiStepFormValues.summary.stackButtonText ?? '',
        },
        enableNotificationOptIn: multiStepFormValues.actions.optInNotifications,
      };

      await createComponentByType(ComponentType.STACK, environmentId, input);
    },
    [createComponentByType, dataComponentId, environmentId, savedFormValues],
  );

  const editStack = useCallback(
    async (input: EditStackComponentInput) => {
      await editStackComponentConfig(environmentId, input);
    },
    [editStackComponentConfig, environmentId],
  );

  const handleUpdateStackClick = useCallback(
    async (values: SummaryFormValues) => {
      if (!stack) {
        return;
      }

      const multiStepFormValues: MultiStepFormValues = {
        ...savedFormValues,
        summary: values,
      };

      const mutableProps: MutableStackComponentProps = {
        insightsPrompt: multiStepFormValues.insights.prompt,
        actionConfig: {
          buttonLink: multiStepFormValues.actions.actionButtonLink ?? '',
          buttonText: multiStepFormValues.actions.actionButtonText ?? '',
          enableButton: multiStepFormValues.actions.includeActionButton,
        },
        summaryConfig: {
          buttonText: multiStepFormValues.summary.stackButtonText ?? '',
        },
        enableNotificationOptIn: multiStepFormValues.actions.optInNotifications,
      };

      await editStack({
        componentId: stack.id,
        name: multiStepFormValues.summary.stackTitle ?? '',
        mutableProps,
      });
    },
    [editStack, savedFormValues, stack],
  );

  const stepActions = useMemo<StepActionsType>(
    () => ({
      insights: {
        primary: {
          text: 'Continue to actions',
          action: (values: FormikValues) =>
            continueToActions(values as InsightsFormValues),
        },
      },
      actions: {
        primary: {
          text: 'Continue to summary',
          action: (values: FormikValues) =>
            continueToSummary(values as ActionsFormValues),
        },
      },
      summary: {
        primary: {
          text: isEditing ? 'Update stack' : 'Create stack',
          action: async (values: FormikValues) => {
            if (isEditing) {
              await handleUpdateStackClick(values as SummaryFormValues);
              hideDialog();
            } else {
              await handleCreateStackClick(values as SummaryFormValues);
              hideDialog();
            }
          },
          loading: isEditing ? editStackLoading : createStackLoading,
        },
      },
    }),
    [
      continueToActions,
      continueToSummary,
      createStackLoading,
      editStackLoading,
      handleCreateStackClick,
      handleUpdateStackClick,
      hideDialog,
      isEditing,
    ],
  );

  return (
    <Stack id='create-stack-form' height='100%'>
      <Stepper activeStep={activeStep} steps={steps} />
      <SteppedFormLayout
        index={0}
        activeIndex={activeStep}
        id='insights-form-container'
        goBack={goBack}
        initialFormValues={savedFormValues.insights}
        validationSchema={insightsValidationSchema}
        primaryButtonConfig={stepActions.insights.primary}
      >
        <InsightsForm baseDataComponent={baseDataComponent} stack={stack} />
      </SteppedFormLayout>
      <SteppedFormLayout
        index={1}
        activeIndex={activeStep}
        id='actions-form-container'
        goBack={goBack}
        initialFormValues={savedFormValues.actions}
        validationSchema={actionsValidationSchema}
        primaryButtonConfig={stepActions.actions.primary}
      >
        <ActionsForm />
      </SteppedFormLayout>
      <SteppedFormLayout
        index={2}
        activeIndex={activeStep}
        id='summary-form-container'
        goBack={goBack}
        initialFormValues={savedFormValues.summary}
        validationSchema={summaryValidationSchema}
        primaryButtonConfig={stepActions.summary.primary}
      >
        <SummaryForm />
      </SteppedFormLayout>
    </Stack>
  );
};

export default CreateOrEditStackDialog;
