import { noop } from 'lodash';
import { useCallback, useRef } from 'react';
import { useRouter } from 'next/router';
import { useAppDispatch, useAppSelector } from './store';
import { useSessionInfo } from './session';
import { useActionToast } from './useActionToast';

import {
  // workspace
  Workspace,
  WorkspaceQuery,
  useWorkspaceLazyQuery,
  useEditWorkspaceMutation,
  EditWorkspaceMutationVariables,
  PaginationArgs,
  // features
  Feature,
  useDeleteWorkspaceFeatureMutation,
  useEditWorkspaceFeatureMutation,
  // members
  WorkspaceMember,
  // integration
  Integration,
  useDeleteIntegrationMutation,
  DeleteIntegrationInput,
  // explores
  WorkspaceLookerDataModelExplore,
  useWorkspaceAssociatedExploresLazyQuery,
  useCreateWorkspaceLookerDataModelExploreMutation,
  useDeleteWorkspaceLookerDataModelExploreMutation,
  CreateWorkspaceLookerDataModelExploreInput,
  DeleteWorkspaceLookerDataModelExploreInput,
  DeleteWorkspaceFeatureInput,
} from '../generated/types';

import {
  setWorkspace,
  setFeatures,
  updateFeatureName as updateFeatureNameAction,
  deleteFeature,
  setMembers,
  setIntegrations,
  deleteIntegration as deleteIntegrationFromStore,
  setWorkspaceExplores,
  addWorkspaceExplore,
  deleteWorkspaceExplore,
  clearWorkspace,
  setDataSourceOptions,
  setWorkspaceInitializationState,
  WorkspaceInitializationState,
  INITIALIZED_WORKSPACE_ID_KEY,
} from '../store/slices/workspace';
import { resetChatThreads } from '../store/slices/chatThreads';
import { ApolloError, OperationVariables } from '@apollo/client';
import { useEmbeddingContext } from '../contexts/EmbeddingProvider';
import { convertToExploreLabel } from '../utils/Strings';
import { setUIState } from '../lib/localStorage';
import {
  AUTOMATIC_EXPLORE_LABEL,
  AUTOMATIC_EXPLORE_VALUE,
} from '../components/WorkspaceChat/constants';

const RECORDS_PER_PAGE_FEATURES = 50;
const RECORDS_PER_PAGE_MEMBERS = 10;

const paginationArgsFeatures: PaginationArgs = {
  first: RECORDS_PER_PAGE_FEATURES,
};
const paginationArgsMembers: PaginationArgs = {
  first: RECORDS_PER_PAGE_MEMBERS,
};

const fetchVariables = {
  featuresParams: { pagination: paginationArgsFeatures },
  membersParams: { pagination: paginationArgsMembers },
  fetchPolicy: 'notify-on-network-status-change',
};

export const useWorkspace = () => {
  const router = useRouter();
  const { executeMutationWithToast } = useActionToast();
  const storeDispatch = useAppDispatch();
  const { features } = useAppSelector((store) => store.workspace.value);
  const { initializationState } = useAppSelector(
    (store) => store.workspace.value,
  );

  const { isEmbedded } = useEmbeddingContext();
  const { isWorkspaceViewer } = useSessionInfo();

  const canModifyWorkspace = !isEmbedded && !isWorkspaceViewer;

  const handleWorkspaceError = useCallback(
    (error: ApolloError) => {
      if (error instanceof ApolloError) {
        const hasPermissionDenied = error.graphQLErrors.some(
          (err) => err.extensions.code === 'PERMISSION_DENIED',
        );
        const hasBadUserInput = error.graphQLErrors.some(
          (err) => err.extensions.code === 'BAD_USER_INPUT',
        );

        if (hasPermissionDenied) {
          router.push('/403');
        } else if (hasBadUserInput) {
          router.push('/404');
        }
      }
      throw error;
    },
    [router],
  );

  const handleChanges = useCallback(
    (data: WorkspaceQuery) => {
      if (data && data?.node?.__typename === 'Workspace') {
        // @TODO: improve below to filter out unnecessary storeDispatches
        // when fetchMore with specific variable is called

        // workspace
        storeDispatch(setWorkspace(data.node as Workspace));

        // features
        if (data.node.features) {
          storeDispatch(
            setFeatures(
              data.node.features.edges.map((edge) => edge.node as Feature),
            ),
          );
        }

        // members
        if (data.node.members) {
          storeDispatch(
            setMembers(
              data.node.members.edges.map(
                (edge) => edge.node as WorkspaceMember,
              ),
            ),
          );
        }
        storeDispatch(setIntegrations(data.node.integrations as Integration[]));

        if (data.node.integrations) {
          storeDispatch(
            setIntegrations(data.node.integrations as Integration[]),
          );
        }
      }
    },
    [storeDispatch],
  );

  // workspace
  const [fetchWS, { loading, error, fetchMore }] = useWorkspaceLazyQuery();
  const [editWS] = useEditWorkspaceMutation();

  const fetchWorkspace = useCallback(
    async (id: string) => {
      if (!id) return;
      const { data, error } = await fetchWS({
        variables: {
          id,
          ...fetchVariables,
          skipFeatures: false,
          skipAdminLists: !canModifyWorkspace, // skip lists that require admin permissions
        },
      });
      if (data) {
        handleChanges(data);
      }
      if (error) {
        handleWorkspaceError(error);
      }
    },
    [canModifyWorkspace, fetchWS, handleChanges, handleWorkspaceError],
  );

  const fetchMoreWorkspace = useCallback(
    async (variables: OperationVariables) => {
      const newVariables = {
        ...fetchVariables,
        ...variables,
      };

      if (fetchMore) {
        const { data, error } = await fetchMore({
          variables: newVariables,
        });
        if (data) {
          handleChanges(data);
        }
        if (error) {
          console.error(error);
        }
      }
    },
    [fetchMore, handleChanges],
  );

  const resetWorkspace = useCallback(async () => {
    storeDispatch(clearWorkspace());
    storeDispatch(resetChatThreads());
  }, [storeDispatch]);

  const editWorkspace = useCallback(
    async (input: EditWorkspaceMutationVariables) => {
      try {
        const result = await editWS({ variables: input });
        const updated = result.data?.editWorkspace?.workspace as Workspace;
        if (updated) {
          storeDispatch(setWorkspace(updated));
        }
        return updated;
      } catch {
        // error already handled by the ErrorLink, just make sure ApolloError is handled
        // and return null so the caller can response to the failure to edit accordingly
        return null;
      }
    },
    [editWS, storeDispatch],
  );

  // features
  const [deleteWorkspaceFeature] = useDeleteWorkspaceFeatureMutation();

  const deleteFeatureFromWorkspace = useCallback(
    async (input: DeleteWorkspaceFeatureInput) => {
      executeMutationWithToast(
        () => deleteWorkspaceFeature({ variables: { input } }),
        'deleteWorkspaceFeature',
        'Alert deleted',
        'Failed to delete alert',
        () => {
          storeDispatch(deleteFeature(input.featureId));
        },
      );
    },
    [deleteWorkspaceFeature, executeMutationWithToast, storeDispatch],
  );

  const [editWorkspaceFeature] = useEditWorkspaceFeatureMutation();

  const updateFeatureName = useCallback(
    async (featureId: string, name: string) => {
      const originalName =
        features.find((feature) => feature.id === featureId)?.name ?? '';

      const revertNameChange = () => {
        storeDispatch(
          updateFeatureNameAction({ id: featureId, name: originalName }),
        );
        return false;
      };

      // update the store first so the UI can reflect the change immediately
      storeDispatch(
        updateFeatureNameAction({
          id: featureId,
          name,
        }),
      );

      executeMutationWithToast(
        () =>
          editWorkspaceFeature({ variables: { input: { featureId, name } } }),
        'editWorkspaceFeature',
        'Alert name updated',
        'Failed to update alert name',
        undefined,
        () => {
          revertNameChange();
        },
      );
    },
    [editWorkspaceFeature, executeMutationWithToast, features, storeDispatch],
  );

  // integrations
  const [deleteIntegration] = useDeleteIntegrationMutation();

  const deleteWorkspaceIntegration = useCallback(
    async (input: DeleteIntegrationInput) => {
      await deleteIntegration({ variables: { input } }).catch(noop);
      storeDispatch(deleteIntegrationFromStore(input.integrationId));
    },
    [deleteIntegration, storeDispatch],
  );

  // explores
  // notifyOnNetworkStatusChange and fetchPolicy are here so we have a working loading state
  const [fetchWSExplores, { loading: workspaceExploresLoading }] =
    useWorkspaceAssociatedExploresLazyQuery();
  const [deleteWorkspaceLookerDataModelExplore] =
    useDeleteWorkspaceLookerDataModelExploreMutation();
  const [createWorkspaceLookerDataModelExplore] =
    useCreateWorkspaceLookerDataModelExploreMutation();

  const fetchWorkspaceExplores = useCallback(
    async (workspaceId: string) => {
      const result = await fetchWSExplores({
        variables: { workspaceId },
      });
      const data = result?.data;
      if (
        data &&
        data.node?.__typename === 'Workspace' &&
        data.node?.lookerExplores
      ) {
        const workspaceExplores = data.node
          .lookerExplores as WorkspaceLookerDataModelExplore[];
        storeDispatch(setWorkspaceExplores(workspaceExplores));

        // set data source options
        const workspaceExploreOptions = workspaceExplores?.map((wsExplore) => {
          return {
            label: convertToExploreLabel(wsExplore.explore.exploreName),
            value: wsExplore.id, // Do not use wsExplore.exploreId, this refers to the environment scope explore id
          };
        });

        const allOptions = [
          {
            label: AUTOMATIC_EXPLORE_LABEL,
            value: AUTOMATIC_EXPLORE_VALUE,
          },
          ...(workspaceExploreOptions ?? []),
        ];
        storeDispatch(setDataSourceOptions(allOptions));
      }
    },
    [fetchWSExplores, storeDispatch],
  );

  const createWorkspaceExplore = useCallback(
    async (input: CreateWorkspaceLookerDataModelExploreInput) => {
      try {
        const result = await createWorkspaceLookerDataModelExplore({
          variables: { input },
        });
        if (
          result?.data?.createWorkspaceLookerDataModelExplore?.workspaceExplore
        ) {
          const workspaceLookerDataModelExplore = result.data
            .createWorkspaceLookerDataModelExplore
            .workspaceExplore as WorkspaceLookerDataModelExplore;
          storeDispatch(addWorkspaceExplore(workspaceLookerDataModelExplore));
        }
      } catch (e: unknown) {
        console.error(e);
      }
    },
    [createWorkspaceLookerDataModelExplore, storeDispatch],
  );

  const removeExploreFromWorkspace = useCallback(
    async (input: DeleteWorkspaceLookerDataModelExploreInput) => {
      try {
        const result = await deleteWorkspaceLookerDataModelExplore({
          variables: { input },
        });
        if (result.data?.deleteWorkspaceLookerDataModelExplore?.deleted) {
          storeDispatch(deleteWorkspaceExplore(input.id));
        }
      } catch (e: unknown) {
        console.error(e);
      }
    },
    [deleteWorkspaceLookerDataModelExplore, storeDispatch],
  );

  // Add an AbortController reference
  const initializationController = useRef<AbortController | null>(null);

  const initializeWorkspace = useCallback(
    async (workspaceId: string) => {
      // Abort any ongoing initialization
      if (initializationController.current) {
        initializationController.current.abort();
      }
      initializationController.current = new AbortController();

      // Check if already initializing after abort
      if (initializationState === WorkspaceInitializationState.INITIALIZING) {
        return;
      }

      storeDispatch(
        setWorkspaceInitializationState(
          WorkspaceInitializationState.INITIALIZING,
        ),
      );

      try {
        await fetchWorkspace(workspaceId);
        await fetchWorkspaceExplores(workspaceId);
        storeDispatch(
          setWorkspaceInitializationState(
            WorkspaceInitializationState.INITIALIZED,
          ),
        );
        setUIState(INITIALIZED_WORKSPACE_ID_KEY, workspaceId);
      } catch (error: unknown) {
        if (!(error instanceof DOMException && error.name === 'AbortError')) {
          handleWorkspaceError(error as ApolloError);
        }
        storeDispatch(
          setWorkspaceInitializationState(
            WorkspaceInitializationState.NOT_INITIALIZED,
          ),
        );
      }
    },
    [
      fetchWorkspace,
      fetchWorkspaceExplores,
      handleWorkspaceError,
      initializationState,
      storeDispatch,
    ],
  );

  return {
    // workspace
    initializeWorkspace,
    initializationState,
    fetchWorkspace,
    fetchMoreWorkspace,
    resetWorkspace,
    editWorkspace,
    loading,
    error,
    // feature
    deleteFeatureFromWorkspace,
    updateFeatureName,
    // integration
    deleteWorkspaceIntegration,
    // explores
    fetchWorkspaceExplores,
    createWorkspaceExplore,
    removeExploreFromWorkspace,
    workspaceExploresLoading,
    // others
    RECORDS_PER_PAGE_FEATURES,
    RECORDS_PER_PAGE_MEMBERS,
  };
};
