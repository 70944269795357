import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Component, ComponentType } from '../../generated/types';

export type EnvComponentType =
  | ComponentType.DATA
  | ComponentType.STACK
  | ComponentType.TOPIC;

export const ENV_COMPONENT_TYPES = [
  ComponentType.DATA,
  ComponentType.STACK,
  ComponentType.TOPIC,
] as const;

type ComponentTypeToKey = {
  [K in EnvComponentType]: K extends ComponentType.DATA
    ? 'dataComponents'
    : K extends ComponentType.STACK
    ? 'stackComponents'
    : 'topicComponents';
};

const COMPONENT_TYPE_TO_KEY: ComponentTypeToKey = {
  [ComponentType.DATA]: 'dataComponents',
  [ComponentType.STACK]: 'stackComponents',
  [ComponentType.TOPIC]: 'topicComponents',
};

interface EnvComponentsState {
  [environmentId: string]: {
    // undefined when it's never loaded
    dataComponents: Component[] | undefined;
    stackComponents: Component[] | undefined;
    topicComponents: Component[] | undefined;
  };
}

const initialState: EnvComponentsState = {};

const envComponentsSlice = createSlice({
  name: 'envComponents',
  initialState: {
    value: initialState,
  },
  reducers: {
    setEnvComponentsByType: (
      state,
      action: PayloadAction<{
        environmentId: string;
        componentType: EnvComponentType;
        components: Component[];
      }>,
    ) => {
      const { environmentId, componentType, components } = action.payload;
      const typeKey = COMPONENT_TYPE_TO_KEY[componentType];
      if (!state.value[environmentId]) {
        state.value[environmentId] = {
          dataComponents: undefined,
          stackComponents: undefined,
          topicComponents: undefined,
        };
      }
      state.value[environmentId][typeKey] = components;
    },
    addEnvComponentByType: (
      state,
      action: PayloadAction<{
        environmentId: string;
        componentType: EnvComponentType;
        component: Component;
      }>,
    ) => {
      const { environmentId, componentType, component } = action.payload;
      if (!state.value[environmentId]) {
        state.value[environmentId] = {
          dataComponents: undefined,
          stackComponents: undefined,
          topicComponents: undefined,
        };
      }
      const typeKey = COMPONENT_TYPE_TO_KEY[componentType];
      const newArray = state.value[environmentId][typeKey] || [];
      newArray.push(component);
      state.value[environmentId][typeKey] = newArray;
    },
    updateEnvComponentByType: (
      state,
      action: PayloadAction<{
        environmentId: string;
        componentType: EnvComponentType;
        component: Component;
      }>,
    ) => {
      const {
        environmentId,
        componentType,
        component: newComponent,
      } = action.payload;
      const typeKey = COMPONENT_TYPE_TO_KEY[componentType];
      if (!state.value[environmentId] || !state.value[environmentId][typeKey]) {
        return state;
      }
      state.value[environmentId][typeKey] = state.value[environmentId][
        typeKey
      ]?.map((component) =>
        component.id === newComponent.id ? newComponent : component,
      );
    },
    removeEnvComponentByType: (
      state,
      action: PayloadAction<{
        environmentId: string;
        componentType: EnvComponentType;
        componentId: string;
      }>,
    ) => {
      const { environmentId, componentType, componentId } = action.payload;
      const typeKey = COMPONENT_TYPE_TO_KEY[componentType];
      if (!state.value[environmentId] || !state.value[environmentId][typeKey]) {
        return;
      }
      state.value[environmentId][typeKey] = state.value[environmentId][
        typeKey
      ]?.filter((dataComponent) => dataComponent.id !== componentId);
    },
  },
});

export const {
  setEnvComponentsByType,
  addEnvComponentByType,
  updateEnvComponentByType,
  removeEnvComponentByType,
} = envComponentsSlice.actions;
export default envComponentsSlice.reducer;
