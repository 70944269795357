import { Components, Theme } from '@mui/material/styles';
import { typography } from './typography';

export const getComponents = (theme: Theme): Components => ({
  MuiDialog: {
    styleOverrides: {
      paper: {
        borderRadius: '9px',
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        ':hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.06)',
        },
      },
    },
  },
  MuiListItemButton: {
    styleOverrides: {
      root: {
        ':hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.06)',
        },
        '&.Mui-selected': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          },
        },
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.06)',
        },
        '&.Mui-selected': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          },
        },
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      root: {
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.06)',
        },
        '&.Mui-selected': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          },
        },
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        '&.Mui-focused': {
          color: theme.palette.text.primary,
        },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        minHeight: '40px',
        borderColor: theme.palette.border.actionable,
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: '8px',
        borderColor: theme.palette.border.actionable,
      },
      notchedOutline: {
        borderColor: theme.palette.border.actionable,
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        color: theme.palette.text.primary,
        a: {
          textDecoration: 'underline',
          color: 'inherit',
        },
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: 'none',
        fontSize: typography.h5?.fontSize,
        color: theme.palette.text.secondary,
        '&.Mui-selected': {
          color: theme.palette.text.primary,
        },
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        '& .MuiTabs-indicator': {
          backgroundColor: theme.palette.neutrals.black,
        },
      },
    },
  },
});
