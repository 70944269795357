import { createSlice } from '@reduxjs/toolkit';
import { setUIState, getUIState } from '../../lib/localStorage';
import { MaterializedComponent } from '../../generated/types';
import { generateInsightId } from '../../utils/generateInsightId';

// Local storage key constants for tracking user interaction state (read/dismissed status)
const STORAGE_KEYS = {
  READ_COMPONENT: 'readComponentIds',
  READ_INSIGHTS: 'readInsightsIds',
  DISMISSED_INSIGHTS: 'dismissedInsightsIds',
} as const;

// Redux state key constants that mirror storage keys
const STATE_KEYS = {
  READ_COMPONENT: 'readComponentIds',
  READ_INSIGHTS: 'readInsightsIds',
  DISMISSED_INSIGHTS: 'dismissedInsightsIds',
} as const;

// Types of user interaction states we track
export type UserInteractionType =
  | 'read-component' // Track which stack components user has viewed/read
  | 'read-insights' // Track which insights user has viewed/read
  | 'dismissed-insights'; // Track which insights user has explicitly dismissed

type StateKey = (typeof STATE_KEYS)[keyof typeof STATE_KEYS];

// Maps interaction types to their corresponding storage and state keys
const KEY_MAPPINGS = {
  'read-component': {
    storage: STORAGE_KEYS.READ_COMPONENT,
    state: STATE_KEYS.READ_COMPONENT,
  },
  'read-insights': {
    storage: STORAGE_KEYS.READ_INSIGHTS,
    state: STATE_KEYS.READ_INSIGHTS,
  },
  'dismissed-insights': {
    storage: STORAGE_KEYS.DISMISSED_INSIGHTS,
    state: STATE_KEYS.DISMISSED_INSIGHTS,
  },
};

// Helper functions
const getStorageKeyForType = (
  type: UserInteractionType,
  workspaceId: string,
): string => {
  // Returns a storage key like "readComponentIds-workspace123" by combining the storage key constant with workspace ID
  return `${KEY_MAPPINGS[type].storage}-${workspaceId}`;
};

const getStateKeyForType = (type: UserInteractionType): StateKey => {
  // Returns the corresponding state key like "readComponentIds" from the mapping
  return KEY_MAPPINGS[type].state;
};

export type InsightUIConfig = {
  id: string;
  title: string;
  body: string;
};

export type StackUIConfig = {
  id: string;
  derivedTimestampedId: string;
  name: string;
  status: 'loading' | 'aborted' | 'success';
  isCurrentUserNotifyOptedIn: boolean;
  materializedComponent?: MaterializedComponent; // optional because environment level stack configs are not materialized. This will be added when it is materialized in the workspace level
  insightUIConfigs?: InsightUIConfig[]; // optional because environment level stack configs are not materialized. This will be added when it is materialized in the workspace level
};

interface StackUIConfigsState {
  [workspaceId: string]: {
    stackUIConfigs: StackUIConfig[];
  } & Record<StateKey, string[]>;
}

const initialState: StackUIConfigsState = {};

const ensureStackUIConfigsStateExistsForWorkspace = (
  stateValue: StackUIConfigsState,
  workspaceId: string,
) => {
  if (!stateValue[workspaceId]) {
    stateValue[workspaceId] = {
      stackUIConfigs: [],
      readComponentIds: [],
      readInsightsIds: [],
      dismissedInsightsIds: [],
    };
  }
};

const StackUIConfigsSlice = createSlice({
  name: 'stackUIConfigs',
  initialState: { value: initialState },
  reducers: {
    setStackUIConfigs: (
      state,
      action: {
        payload: {
          workspaceId: string;
          stackUIConfigs: StackUIConfig[];
        };
      },
    ) => {
      const { workspaceId, stackUIConfigs } = action.payload;
      ensureStackUIConfigsStateExistsForWorkspace(state.value, workspaceId);
      state.value[workspaceId].stackUIConfigs = stackUIConfigs;
    },
    updateStackUIConfig: (
      state,
      action: {
        payload: {
          workspaceId: string;
          stackUIConfig: Partial<StackUIConfig>;
        };
      },
    ) => {
      const { workspaceId, stackUIConfig: newStackUIConfig } = action.payload;
      // if the workspace state doesn't exist, it doesn't make sense to update the stackUIConfig
      if (state.value[workspaceId]?.stackUIConfigs) {
        const newWorkspaceStackUIConfigs = state.value[
          workspaceId
        ].stackUIConfigs.map((stackUIConfig) =>
          stackUIConfig.id === newStackUIConfig.id
            ? {
                ...stackUIConfig,
                ...newStackUIConfig,
              }
            : stackUIConfig,
        );
        state.value[workspaceId].stackUIConfigs = newWorkspaceStackUIConfigs;
      }
    },
    loadUserInteractionStateIDsFromStorage: (
      state,
      action: {
        payload: {
          workspaceId: string;
          type: UserInteractionType;
        };
      },
    ) => {
      const { workspaceId, type } = action.payload;
      ensureStackUIConfigsStateExistsForWorkspace(state.value, workspaceId);

      const storageKey = getStorageKeyForType(type, workspaceId);
      const stateKey = getStateKeyForType(type);
      const ids = getUIState(storageKey);
      if (ids) {
        try {
          state.value[workspaceId][stateKey] = JSON.parse(ids);
        } catch (error) {
          console.error(
            `Failed to parse user interaction state IDs from storage for key ${storageKey}:`,
            error,
          );
          state.value[workspaceId][stateKey] = [];
        }
      }
    },
    addUserInteractionStateId: (
      state,
      action: {
        payload: {
          workspaceId: string;
          id: string;
          type: UserInteractionType;
        };
      },
    ) => {
      const { workspaceId, id, type } = action.payload;
      ensureStackUIConfigsStateExistsForWorkspace(state.value, workspaceId);
      const stateKey = getStateKeyForType(type);
      if (!state.value[workspaceId][stateKey].includes(id)) {
        const newIds = [...state.value[workspaceId][stateKey], id];
        state.value[workspaceId][stateKey] = newIds;
        const storageKey = getStorageKeyForType(type, workspaceId);
        setUIState(storageKey, JSON.stringify(newIds));
      }
    },
    removeUserInteractionStateId: (
      state,
      action: {
        payload: {
          workspaceId: string;
          id: string;
          type: UserInteractionType;
        };
      },
    ) => {
      const { workspaceId, id: idToRemove, type } = action.payload;
      ensureStackUIConfigsStateExistsForWorkspace(state.value, workspaceId);
      const stateKey = getStateKeyForType(type);
      const newIds = state.value[workspaceId][stateKey].filter(
        (id) => id !== idToRemove,
      );
      state.value[workspaceId][stateKey] = newIds;
      const storageKey = getStorageKeyForType(type, workspaceId);
      setUIState(storageKey, JSON.stringify(newIds));
    },
    updateAbortedStackUIConfig: (
      state,
      action: {
        payload: {
          workspaceId: string;
          componentId: string;
        };
      },
    ) => {
      const { workspaceId, componentId } = action.payload;
      ensureStackUIConfigsStateExistsForWorkspace(state.value, workspaceId);
      const componentStackUIConfig = state.value[
        workspaceId
      ].stackUIConfigs.find(
        (stackUIConfig) => stackUIConfig.id === componentId,
      );
      if (componentStackUIConfig) {
        componentStackUIConfig.status = 'aborted';
      }
    },
    updateSuccessStackUIConfig: (
      state,
      action: {
        payload: {
          workspaceId: string;
          materializedComponent: MaterializedComponent;
          userEmail: string;
        };
      },
    ) => {
      const { workspaceId, materializedComponent, userEmail } = action.payload;
      ensureStackUIConfigsStateExistsForWorkspace(state.value, workspaceId);
      const insightUIConfigs: InsightUIConfig[] =
        materializedComponent.derivedContent?.insights?.map(
          (insight: { title: string; body: string }) => {
            const insightId = generateInsightId(insight.body);
            return {
              id: insightId,
              title: insight.title,
              body: insight.body,
            };
          },
        );

      const isCurrentUserNotifyOptedIn =
        materializedComponent.notifyOptIns?.includes(userEmail);

      const newStackUIConfig: StackUIConfig = {
        id: materializedComponent.componentId,
        derivedTimestampedId: `${materializedComponent.componentId}-${materializedComponent.lastDerivedAt}`,
        name: materializedComponent.component.name,
        status: 'success',
        isCurrentUserNotifyOptedIn,
        materializedComponent,
        insightUIConfigs,
      };

      const newWorkspaceStackUIConfigs = state.value[
        workspaceId
      ].stackUIConfigs.map((stackUIConfig) =>
        stackUIConfig.id === newStackUIConfig.id
          ? {
              ...stackUIConfig,
              ...newStackUIConfig,
            }
          : stackUIConfig,
      );
      state.value[workspaceId].stackUIConfigs = newWorkspaceStackUIConfigs;
    },
  },
});

export const {
  setStackUIConfigs,
  updateStackUIConfig,
  loadUserInteractionStateIDsFromStorage,
  addUserInteractionStateId,
  removeUserInteractionStateId,
  updateAbortedStackUIConfig,
  updateSuccessStackUIConfig,
} = StackUIConfigsSlice.actions;
export default StackUIConfigsSlice.reducer;
