import { Routes } from '@madeinventive/core-types';

export const getWorkspaceNavigationUrl = (
  path: string,
  workspaceId?: string,
): string => {
  const firstPath = path.split('/')[1];
  const isWorkspacePage = firstPath === 'workspace';
  const isWorkspaceSettingsPage = firstPath === 'workspace-settings';

  if (isWorkspacePage) {
    return workspaceId ? Routes.workspace(workspaceId) : '/workspace';
  }

  // e.g. `/workspace-settings/[workspaceId]/users`
  if (isWorkspaceSettingsPage) {
    // Get the current path segments
    const pathSegments = path.split('/');

    // Get the last path segment or default to 'general'
    const settingsPath =
      pathSegments.length > 3
        ? pathSegments[pathSegments.length - 1]
        : 'general';

    return workspaceId
      ? Routes.workspaceSettings(workspaceId, settingsPath)
      : '/workspace-settings';
  }

  return path;
};
