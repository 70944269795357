import {
  ClickAwayListener,
  PopperProps,
  Popper,
  ClickAwayListenerProps,
  InputBaseProps,
} from '@mui/material';
import SearchSelect, { SearchSelectProps } from './SearchSelect';

export interface SearchSelectPopperProps<Value>
  extends SearchSelectProps<Value>,
    Pick<PopperProps, 'anchorEl' | 'open' | 'placement'>,
    Pick<ClickAwayListenerProps, 'onClickAway'>,
    Pick<InputBaseProps, 'placeholder'> {}

// This is a wrapper around the MUI Autocomplete component and does search and filtering and selection
const SearchSelectPopper = <Value,>({
  anchorEl,
  open,
  onClickAway,
  placement = 'bottom-end',
  ...rest
}: SearchSelectPopperProps<Value>) => {
  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement={placement}
      // This is to ensure that the popper is above the drawer and modal
      sx={{
        zIndex: (theme) => theme.zIndex.modal + 1,
      }}
    >
      <ClickAwayListener onClickAway={onClickAway}>
        {/* When using custom component as a child of ClickAwayListener, div is needed to ensure that clickAway works without forwardRef. */}
        <div>
          <SearchSelect {...rest} />
        </div>
      </ClickAwayListener>
    </Popper>
  );
};

export default SearchSelectPopper;
