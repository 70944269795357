import { NextRouter } from 'next/router';

// check if the current page is scope to a workspace
// i.e, '/workspace/[workspaceId]', 'workspace-settings/[workspaceId]/general'
export const isWorkspaceRelatedPage = (router?: NextRouter) => {
  if (!router?.pathname) return false;

  const firstPath = router.pathname.split('/')[1];
  return (
    firstPath &&
    (firstPath === 'workspace' || firstPath === 'workspace-settings')
  );
};

export const isWorkspaceMainPage = (router?: NextRouter) => {
  if (!router?.pathname) return false;

  const firstPath = router.pathname.split('/')[1];
  return firstPath && firstPath === 'workspace';
};
