import { noop } from 'lodash';
import { useState, useCallback, useContext, useEffect, useMemo } from 'react';

import {
  Component,
  WorkspaceComponentsDocument,
  useWorkspaceComponentsLazyQuery,
  useSaveChatResponseVisualizationMutation,
  SaveChatResponseVisualizationInput,
  useDeleteComponentMutation,
  useEditComponentMutation,
} from '../generated/types';
import {
  setIsComponentsLoading,
  setComponents,
  updateComponentName,
  addNewComponentId,
  loadInitialNewComponentIds,
  removeNewComponentId,
} from '../store/slices/workspaceComponents';
import { useAppDispatch, useAppSelector, useDialog, useActionToast } from '.';
import { DIALOG_IDS } from '../components/registeredDialogs/dialogRegistry';
import { WorkspaceContext } from '../components/WorkspaceProvider';

export const useWorkspaceComponents = (workspaceId: string) => {
  const { executeMutationWithToast } = useActionToast();
  const storeDispatch = useAppDispatch();

  const componentsFromStore = useAppSelector(
    (store) => store.workspaceComponents.value,
  ).components;

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [savedComponentName, setSavedComponentName] = useState<string>('');

  const savedDialogContentProps = useMemo(
    () => ({
      bodyText: `${savedComponentName} saved successfully.`,
    }),
    [savedComponentName],
  );

  const { fetchMoreWorkspace } = useContext(WorkspaceContext);

  const { showDialog } = useDialog();
  const showSaveVizSuccessDialog = useCallback(
    () =>
      showDialog({
        id: DIALOG_IDS.SUCCESS,
        title: 'Save visualization',
        contentProps: savedDialogContentProps,
      }),
    [savedDialogContentProps, showDialog],
  );

  const [fetchWorkspaceComponents, { data, loading, error: fetchError }] =
    useWorkspaceComponentsLazyQuery();

  const [saveChatResponseVisualization] =
    useSaveChatResponseVisualizationMutation({
      refetchQueries: [
        {
          query: WorkspaceComponentsDocument,
          variables: {
            id: workspaceId,
          },
        },
      ],
    });

  const [deleteComponent] = useDeleteComponentMutation({
    refetchQueries: [
      {
        query: WorkspaceComponentsDocument,
        variables: {
          id: workspaceId,
        },
      },
    ],
  });

  const [updateComponent] = useEditComponentMutation();

  // this updates the components in the store when the data is fetched and also re-fetched.
  useEffect(() => {
    if (data?.node?.__typename === 'Workspace' && data.node.components) {
      const components = data.node.components.edges.map(
        (comp) => comp.node as Component,
      );
      storeDispatch(setComponents(components));
    }
  }, [data, storeDispatch]);

  const loadNewComponentIdsFromLocalStorage = useCallback(() => {
    storeDispatch(loadInitialNewComponentIds());
  }, [storeDispatch]);

  const fetchComponents = useCallback(async () => {
    storeDispatch(setIsComponentsLoading(true));
    try {
      await fetchWorkspaceComponents({
        variables: {
          id: workspaceId,
        },
      });
    } finally {
      storeDispatch(setIsComponentsLoading(false));
    }
  }, [fetchWorkspaceComponents, workspaceId, storeDispatch]);

  const getComponents = useCallback(() => {
    if (componentsFromStore === null) {
      fetchWorkspaceComponents({
        variables: {
          id: workspaceId,
        },
      });
    } else {
      return componentsFromStore;
    }
  }, [componentsFromStore, fetchWorkspaceComponents, workspaceId]);

  const addComponentToWorkspace = useCallback(
    async ({ chatResponseId, name }: SaveChatResponseVisualizationInput) => {
      setIsSaving(true);
      await saveChatResponseVisualization({
        variables: {
          input: {
            chatResponseId,
            name,
          },
        },
      })
        .then((res) => {
          const component = res.data?.saveChatResponseVisualization.component;
          if (component) {
            const { name, id } = component;
            setIsSaving(false);
            setIsSaved(true);
            setSavedComponentName(name);
            showSaveVizSuccessDialog();
            storeDispatch(addNewComponentId(id));
          }
        })
        .catch(noop);
    },
    [saveChatResponseVisualization, showSaveVizSuccessDialog, storeDispatch],
  );

  const dismissNewComponentById = useCallback(
    (id: string) => {
      storeDispatch(removeNewComponentId(id));
    },
    [storeDispatch],
  );

  const deleteComponentFromWorkspace = useCallback(
    async (componentId: string, force?: boolean) => {
      executeMutationWithToast(
        () => deleteComponent({ variables: { input: { componentId, force } } }),
        'deleteComponent',
        'Component deleted.',
        'Failed to delete component.',
        undefined,
        () => {
          fetchMoreWorkspace({}); // a roundabout way to refresh the workspace's feature list
          dismissNewComponentById(componentId);
        },
      );
    },
    [
      deleteComponent,
      dismissNewComponentById,
      executeMutationWithToast,
      fetchMoreWorkspace,
    ],
  );

  const editComponentName = useCallback(
    async (component: Component, name: string) => {
      const originalName = component.name;
      // update the store first so the UI can reflect the change immediately
      storeDispatch(updateComponentName({ id: component.id, name }));

      const revertNameChange = () => {
        storeDispatch(
          updateComponentName({ id: component.id, name: originalName }),
        );
        return false;
      };

      executeMutationWithToast(
        () =>
          updateComponent({
            variables: { input: { componentId: component.id, name } },
          }),
        'editComponent',
        'Component name updated.',
        'Failed to update component name.',
        undefined,
        () => {
          return revertNameChange();
        },
      );
    },
    [executeMutationWithToast, storeDispatch, updateComponent],
  );

  return {
    isSaving,
    isSaved,
    loadNewComponentIdsFromLocalStorage,
    fetchComponents,
    getComponents,
    loading,
    error: fetchError,
    addComponentToWorkspace,
    dismissNewComponentById,
    deleteComponentFromWorkspace,
    editComponentName,
  };
};
