import { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';

// types
import {
  Component,
  ComponentType,
  CreateDataComponentInput,
  CreateDataComponentMutationResult,
  CreateStackFromDataComponentInput,
  CreateStackFromDataComponentMutationResult,
  CreateTopicFromDataComponentInput,
  EditComponentInput,
  EditStackComponentInput,
  EditTopicComponentInput,
  useCreateDataComponentMutation,
  useCreateStackFromDataComponentMutation,
  useCreateTopicFromDataComponentMutation,
  useDeleteComponentMutation,
  useEditComponentMutation,
  useEditTopicComponentMutation,
  useEditStackComponentMutation,
  useEnvironmentComponentsLazyQuery,
  CreateTopicFromDataComponentMutationResult,
} from '../generated/types';

// actions
import {
  ENV_COMPONENT_TYPES,
  EnvComponentType,
  setEnvComponentsByType as setEnvComponentsAction,
  removeEnvComponentByType as removeComponentAction,
  updateEnvComponentByType as updateComponentAction,
  addEnvComponentByType as addEnvComponentAction,
} from '../store/slices/environmentComponents';

// hooks
import { useAppDispatch } from './store';
import { useActionToast, useToast } from './';

// names for components for toast messages
const componentNames = {
  [ComponentType.DATA]: 'Content',
  [ComponentType.STACK]: 'Stack',
  [ComponentType.TOPIC]: 'Topic',
};

export const useEnvironmentComponents = () => {
  const dispatch = useAppDispatch();
  const router = useRouter();

  const { showErrorToast, showDefaultToast } = useToast();
  const { executeMutationWithToast } = useActionToast();

  const [getEnvironmentComponents, { loading }] =
    useEnvironmentComponentsLazyQuery();

  const [editComponentName, { loading: editComponentNameLoading }] =
    useEditComponentMutation();

  const [editTopicComponent, { loading: editTopicComponentLoading }] =
    useEditTopicComponentMutation();

  const [editStackComponent, { loading: editStackComponentLoading }] =
    useEditStackComponentMutation();

  const [deleteComponent, { loading: deleteComponentLoading }] =
    useDeleteComponentMutation();

  const [createDataComponent, { loading: createQueryLoading }] =
    useCreateDataComponentMutation();

  const [createTopicFromChatResponse, { loading: createTopicLoading }] =
    useCreateTopicFromDataComponentMutation();

  const [createStackFromDataComponent, { loading: createStackLoading }] =
    useCreateStackFromDataComponentMutation();

  // if componentType is undefined, load all env components
  const loadEnvironmentComponentsByType = useCallback(
    async (environmentId: string, componentType?: EnvComponentType) => {
      const { data } = await getEnvironmentComponents({
        variables: {
          environmentId,
          params: {
            type: componentType,
          },
        },
      });

      if (data?.node?.__typename === 'Environment' && environmentId) {
        if (componentType) {
          // Handle single component type case
          const components = data.node.components.edges
            .map((edge) => edge.node as Component)
            .filter((component) => component.type === componentType);
          dispatch(
            setEnvComponentsAction({
              environmentId,
              componentType,
              components,
            }),
          );
        } else {
          // Handle all component types case
          const allComponents = data.node.components.edges.map(
            (edge) => edge.node as Component,
          );

          ENV_COMPONENT_TYPES.forEach((envType) => {
            const typeComponents = allComponents.filter(
              (component) => component.type === envType,
            );
            dispatch(
              setEnvComponentsAction({
                environmentId,
                componentType: envType,
                components: typeComponents,
              }),
            );
          });
        }
      }
    },
    [getEnvironmentComponents, dispatch],
  );

  const editEnvironmentComponentName = useCallback(
    async (
      environmentId: string,
      input: EditComponentInput,
      componentType: EnvComponentType,
    ) => {
      await executeMutationWithToast(
        () => editComponentName({ variables: { input } }),
        'editComponent',
        `${componentNames[componentType]} updated.`,
        `${componentNames[componentType]} not updated. Please retry.`,
        (data) => {
          const component = data?.editComponent.component as Component;
          if (component && environmentId) {
            dispatch(
              updateComponentAction({
                environmentId,
                componentType: componentType,
                component,
              }),
            );
          }
        },
      );
    },
    [dispatch, editComponentName, executeMutationWithToast],
  );

  const editStackComponentConfig = useCallback(
    async (environmentId: string, input: EditStackComponentInput) => {
      await executeMutationWithToast(
        () => editStackComponent({ variables: { input } }),
        'editStackComponent',
        `${componentNames[ComponentType.STACK]} updated.`,
        `${componentNames[ComponentType.STACK]} not updated. Please retry.`,
        (data) => {
          const component = data?.editStackComponent.component as Component;
          if (component && environmentId) {
            dispatch(
              updateComponentAction({
                environmentId,
                componentType: ComponentType.STACK,
                component,
              }),
            );
          }
        },
      );
    },
    [dispatch, editStackComponent, executeMutationWithToast],
  );

  const editTopicComponentConfig = useCallback(
    async (environmentId: string, input: EditTopicComponentInput) => {
      await executeMutationWithToast(
        () => editTopicComponent({ variables: { input } }),
        'editTopicComponent',
        `${componentNames[ComponentType.TOPIC]} updated.`,
        `${componentNames[ComponentType.TOPIC]} not updated. Please retry.`,
        (data) => {
          const component = data?.editTopicComponent.component as Component;
          if (component && environmentId) {
            dispatch(
              updateComponentAction({
                environmentId,
                componentType: ComponentType.TOPIC,
                component,
              }),
            );
          }
        },
      );
    },
    [dispatch, editTopicComponent, executeMutationWithToast],
  );

  const deleteEnvironmentComponentByType = useCallback(
    async (
      environmentId: string,
      componentId: string,
      componentType: EnvComponentType,
    ) => {
      executeMutationWithToast(
        () => deleteComponent({ variables: { input: { componentId } } }),
        'deleteComponent',
        `${componentNames[componentType]} deleted.`,
        `${componentNames[componentType]} not deleted. Please retry.`,
        () => {
          dispatch(
            removeComponentAction({
              environmentId,
              componentId,
              componentType: componentType,
            }),
          );
        },
      );
    },
    [deleteComponent, dispatch, executeMutationWithToast],
  );

  type MutationTypeMap = {
    [ComponentType.DATA]: {
      input: CreateDataComponentInput;
      result: CreateDataComponentMutationResult;
    };
    [ComponentType.STACK]: {
      input: CreateStackFromDataComponentInput;
      result: CreateStackFromDataComponentMutationResult;
    };
    [ComponentType.TOPIC]: {
      input: CreateTopicFromDataComponentInput;
      result: CreateTopicFromDataComponentMutationResult;
    };
  };

  const componentTypeConfig = useMemo(
    () =>
      ({
        [ComponentType.DATA]: {
          mutation: createDataComponent,
          redirectPath: '/create/queries',
        },
        [ComponentType.STACK]: {
          mutation: createStackFromDataComponent,
          redirectPath: '/create/stacks',
        },
        [ComponentType.TOPIC]: {
          mutation: createTopicFromChatResponse,
          redirectPath: '/create/topics',
        },
      } as const),
    [
      createDataComponent,
      createStackFromDataComponent,
      createTopicFromChatResponse,
    ],
  );

  const handleResultComponent = useCallback(
    (
      component: Component,
      environmentId: string,
      componentType: EnvComponentType,
    ) => {
      if (component) {
        if (component && environmentId) {
          dispatch(
            addEnvComponentAction({
              environmentId,
              componentType,
              component: component as Component,
            }),
          );
          showDefaultToast(
            `${componentNames[componentType]} saved to Create.`,
            'View',
            () => {
              router.push(componentTypeConfig[componentType].redirectPath);
            },
          );
        } else {
          showErrorToast(
            `${componentNames[componentType]} not saved to Create. Please retry.`,
          );
        }
      } else {
        showErrorToast(
          `${componentNames[componentType]} not saved to Create. Please retry.`,
        );
      }
    },
    [componentTypeConfig, dispatch, router, showDefaultToast, showErrorToast],
  );

  const createComponentByType = useCallback(
    async <T extends keyof MutationTypeMap>(
      componentType: T,
      environmentId: string,
      input: MutationTypeMap[T]['input'],
    ) => {
      if (componentType === ComponentType.DATA) {
        const result = await createDataComponent({
          variables: {
            input: input as MutationTypeMap[ComponentType.DATA]['input'],
          },
        });
        const component = result?.data?.createDataComponent?.component as
          | Component
          | undefined;
        if (component && environmentId) {
          handleResultComponent(component, environmentId, componentType);
        }
      }

      if (componentType === ComponentType.STACK) {
        const result = await createStackFromDataComponent({
          variables: {
            input: input as MutationTypeMap[ComponentType.STACK]['input'],
          },
        });
        const component = result?.data?.createStackFromDataComponent
          ?.component as Component | undefined;
        if (component && environmentId) {
          handleResultComponent(component, environmentId, componentType);
        }
      }

      if (componentType === ComponentType.TOPIC) {
        const result = await createTopicFromChatResponse({
          variables: {
            input: input as MutationTypeMap[ComponentType.TOPIC]['input'],
          },
        });
        const component = result?.data?.createTopicFromDataComponent
          ?.component as Component | undefined;
        if (component && environmentId) {
          handleResultComponent(component, environmentId, componentType);
        }
      }
    },
    [
      createDataComponent,
      createStackFromDataComponent,
      createTopicFromChatResponse,
      handleResultComponent,
    ],
  );

  return {
    loadEnvironmentComponentsByType,
    loading,
    editEnvironmentComponentName,
    editComponentNameLoading,
    editStackComponentConfig,
    editStackComponentLoading,
    editTopicComponentConfig,
    editTopicComponentLoading,
    deleteEnvironmentComponentByType,
    deleteComponentLoading,
    createComponentByType,
    createComponentLoading:
      createQueryLoading || createTopicLoading || createStackLoading,
  };
};

export default useEnvironmentComponents;
