import { useEffect, ReactNode } from 'react';
import LogRocket from 'logrocket';

//components
import { Stack } from '@mui/material';
import Modals from '../modals';
import Header from '../Header';

// hooks and context
import { useSessionInfo } from '../../hooks';
import { useEmbeddingContext } from '../../contexts/EmbeddingProvider';
import EmbedLayout from './EmbedLayout';
import WebAppLayout from './WebAppLayout';

interface LayoutProps {
  children?: ReactNode;
}

const RootLayout = ({ children }: LayoutProps) => {
  const { isEmbedded } = useEmbeddingContext();
  const { isSignedIn, userType, email, firstName, lastName } = useSessionInfo();

  useEffect(() => {
    if (isSignedIn) {
      LogRocket.identify(email, {
        name: `${firstName} ${lastName}`,
        email: email,
        userType: userType,
        env: process.env.NEXT_PUBLIC_ENV ?? 'local',
      });
    }
  }, [email, firstName, isSignedIn, lastName, userType]);

  return (
    <>
      <Header />
      <Modals />
      <Stack
        id='view'
        height='100vh'
        overflow='hidden'
        bgcolor='background.paper'
      >
        {isEmbedded && <EmbedLayout>{children}</EmbedLayout>}
        {!isEmbedded && <WebAppLayout>{children}</WebAppLayout>}
      </Stack>
    </>
  );
};

export default RootLayout;
