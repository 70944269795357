import { createContext, useEffect, useMemo, ReactNode, useRef } from 'react';
import { useRouter } from 'next/router';
import { useWorkspace, useAppSelector } from '../hooks';
import { useSessionInfo } from '../hooks/session';

import WorkspaceVFPPreFetcher from './WorkspaceVFPPrefetcher';
import WorkspaceComponentsPreFetcher from './WorkspaceComponentsPreFetcher';
import MaterializedComponentsBackgroundManager from './WorkspaceStacks/MaterializedComponentsBackgroundManager';
import {
  INITIALIZED_WORKSPACE_ID_KEY,
  WorkspaceInitializationState,
} from '../store/slices/workspace';
import { getWorkspaceNavigationUrl } from '../utils/getWorkspaceNavigationUrl';
import { getUIState } from '../lib/localStorage';

interface WorkspaceContextStore extends ReturnType<typeof useWorkspace> {}

const initialValue: WorkspaceContextStore = {
  // workspace
  initializeWorkspace: async () => {
    /** */
  },
  initializationState: WorkspaceInitializationState.NOT_INITIALIZED,
  fetchWorkspace: async () => {
    /** */
  },
  fetchMoreWorkspace: async () => {
    /** */
  },
  loading: false,
  error: undefined,
  editWorkspace: async () => {
    return null;
  },
  deleteWorkspaceIntegration: async () => {
    /** */
  },
  RECORDS_PER_PAGE_MEMBERS: 0,
  fetchWorkspaceExplores: async () => {
    /** */
  },
  createWorkspaceExplore: async () => {
    /** */
  },
  removeExploreFromWorkspace: async () => {
    /** */
  },
  resetWorkspace: async () => {
    /** */
  },
  deleteFeatureFromWorkspace: async () => {
    /** */
  },
  updateFeatureName: async () => {
    /** */
  },
  workspaceExploresLoading: false,
  RECORDS_PER_PAGE_FEATURES: 0,
};

export const WorkspaceContext = createContext(initialValue);

interface WorkspaceProviderProps {
  children: ReactNode;
}

const WorkspaceProvider = ({ children }: WorkspaceProviderProps) => {
  const router = useRouter();
  const { isSignedIn } = useSessionInfo();
  const { workspace } = useAppSelector((store) => store.workspace.value);
  const isEnvironmentPage = router.asPath === '/environment';
  const firstPath = router.pathname.split('/')[1];
  const isWorkspacePage =
    firstPath === 'workspace' || firstPath === 'workspace-settings';
  const routerWorkspaceId = router.query.workspaceId as string | undefined;
  const storedWorkspaceId = useRef(workspace?.id);
  const lastInitializedWorkspaceId = getUIState(INITIALIZED_WORKSPACE_ID_KEY);

  const value = useWorkspace();
  const memoizedValue = useMemo(() => value, [value]);
  const { initializeWorkspace, initializationState } = value;

  const { envWorkspaceOptions } = useAppSelector(
    (store) => store.environment.value,
  );

  useEffect(() => {
    // It should not initialize workspace when the page is not a workspace page
    if (!isSignedIn || !isWorkspacePage) return;

    if (initializationState === WorkspaceInitializationState.INITIALIZING) {
      return;
    }

    if (routerWorkspaceId && routerWorkspaceId !== storedWorkspaceId.current) {
      // Handle workspace change from URL
      // This includes workspace change from workspace selector
      storedWorkspaceId.current = routerWorkspaceId;
      initializeWorkspace(routerWorkspaceId);
    } else if (!routerWorkspaceId && envWorkspaceOptions) {
      if (envWorkspaceOptions.length === 1) {
        // Handle single workspace auto-initialization
        const singleWorkspace = envWorkspaceOptions[0];
        storedWorkspaceId.current = singleWorkspace.id;

        // Update URL to include workspace ID
        // We must add the workspaceId to the URL, otherwise this block will be re-run forever
        const newUrl = getWorkspaceNavigationUrl(
          router.pathname,
          singleWorkspace.id,
        );
        initializeWorkspace(singleWorkspace.id);
        router.push(newUrl, undefined, { shallow: true });
      } else if (
        envWorkspaceOptions.length > 1 &&
        lastInitializedWorkspaceId &&
        envWorkspaceOptions.find(
          (option) => option.id === lastInitializedWorkspaceId,
        )
      ) {
        // do not need to re-initialize workspace
        // just re-route to the last initialized workspace
        const newUrl = getWorkspaceNavigationUrl(
          router.pathname,
          lastInitializedWorkspaceId,
        );
        router.push(newUrl, undefined, { shallow: true });
      }
    }
  }, [
    isSignedIn,
    routerWorkspaceId,
    initializeWorkspace,
    router,
    initializationState,
    isEnvironmentPage,
    isWorkspacePage,
    workspace,
    envWorkspaceOptions,
    lastInitializedWorkspaceId,
  ]);

  return (
    <>
      <WorkspaceContext.Provider value={memoizedValue}>
        {children}
      </WorkspaceContext.Provider>
      {workspace?.id && (
        <>
          <WorkspaceVFPPreFetcher workspaceId={workspace.id} />
          <WorkspaceComponentsPreFetcher workspaceId={workspace.id} />
          <MaterializedComponentsBackgroundManager workspaceId={workspace.id} />
        </>
      )}
    </>
  );
};

export default WorkspaceProvider;
