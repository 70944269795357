import React from 'react';
import { Dialog as MuiDialog, DialogContent } from '@mui/material';
import DialogActions from './DialogActions';
import PanelHeader from '../Panel/PanelHeader';
import { DialogActionType } from '../../../contexts/DialogProvider';
import {
  DIALOG_IDS,
  DIALOG_REGISTRY,
  DialogComponentPropsMapping,
} from '../../registeredDialogs/dialogRegistry';

interface DialogProps<ID extends DIALOG_IDS> {
  id: ID;
  open: boolean;
  hideClose?: boolean;
  title: string;
  fullDisplay?: boolean;
  contentProps: DialogComponentPropsMapping[ID];
  primaryAction?: DialogActionType;
  secondaryAction?: DialogActionType;
  children?: React.ReactNode;
}

const Dialog = <ID extends DIALOG_IDS>({
  id,
  open,
  hideClose = false,
  title,
  fullDisplay,
  contentProps,
  primaryAction,
  secondaryAction,
  children,
}: DialogProps<ID>) => {
  const { hideDialog } = contentProps;

  // This needs type assertion because id extends DIALOG_IDs and
  // TypeScript infers the intersection of all possible types for the component
  // ContentComponent props end up being intersection of all possible props instead of union.
  const ContentComponent = DIALOG_REGISTRY[id]?.Component as React.FC<
    DialogComponentPropsMapping[ID]
  >;

  return (
    <MuiDialog
      open={open}
      onClose={hideDialog}
      aria-labelledby='modal-title'
      maxWidth={fullDisplay ? 'xl' : 'sm'}
      fullWidth={true}
      sx={{
        '& .MuiDialog-paper': {
          height: fullDisplay ? '100%' : 'unset',
        },
      }}
    >
      <PanelHeader
        title={title}
        onClose={!hideClose && !!hideDialog ? hideDialog : undefined}
        useMobileLayout={false}
      />
      <DialogContent
        sx={{
          ...(fullDisplay ? { padding: 0 } : { paddingX: 2, paddingY: 1 }),
        }}
      >
        <>
          {children && <>{children}</>}
          {ContentComponent && <ContentComponent {...contentProps} />}
        </>
      </DialogContent>
      {secondaryAction || primaryAction ? (
        <DialogActions
          isBuiltIn={true}
          closeDialog={hideDialog}
          secondaryAction={secondaryAction}
          primaryAction={primaryAction}
        />
      ) : null}
    </MuiDialog>
  );
};

export default Dialog;
