import { useState, useCallback, useContext } from 'react';
import { Stack, Typography } from '@mui/material';

import { BaseRegisteredDialogComponentProps } from './types';
import TextField from '../shared/Inputs/TextField';
import FormActionFooter from '../shared/FormActionFooter';
import { EnvironmentContext } from '../EnvironmentProvider';
import { useEnvironmentComponents } from '../../hooks';
import { ComponentType, CreateDataComponentInput } from '../../generated/types';
export interface SaveToCreateProps extends BaseRegisteredDialogComponentProps {
  entityId: string;
}

const SaveToCreate = ({ entityId, hideDialog }: SaveToCreateProps) => {
  const [title, setTitle] = useState('');

  const { environment } = useContext(EnvironmentContext);
  const environmentId = environment?.id;
  const environmentName = environment?.name;

  const { createComponentByType } = useEnvironmentComponents();

  const handleSubmit = useCallback(() => {
    if (!environmentId) return;
    const input: CreateDataComponentInput = {
      entityId,
      name: title,
    };
    createComponentByType(ComponentType.DATA, environmentId, input);
    hideDialog();
  }, [createComponentByType, environmentId, entityId, title, hideDialog]);

  return (
    <Stack spacing={2}>
      <Typography color='text.secondary'>
        {`${environmentName} administrators can view and share saved items or use
        them to create topics and stacks.`}
      </Typography>
      <TextField
        label='Title'
        subLabel='Choose a clear, descriptive title that reflects the purpose of this content.'
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <FormActionFooter
        noPadding
        cancelAction={{
          label: 'Cancel',
          action: hideDialog,
        }}
        submitAction={{
          label: 'Save to Create',
          action: handleSubmit,
          disabled: !title.trim(),
        }}
      />
    </Stack>
  );
};

export default SaveToCreate;
