import { useCallback, useEffect } from 'react';
import {
  useWorkspaceChatThreadsLazyQuery,
  useWorkspaceChatThreadLazyQuery,
  useEditWorkspaceChatThreadMutation,
  useDeleteWorkspaceChatThreadMutation,
  ChatThread,
} from '../generated/types';
import { useActionToast, useAppDispatch, useAppSelector } from '.';
import {
  setChatThreads,
  updateChatThreadTitle,
  deleteChatThread,
  setIsSelectedChatThreadLoaded,
  setSelectedChatThreadId as setSelectedChatThreadIdAction,
} from '../store/slices/chatThreads';
import { updateLoadedChatByThreadId } from '../store/slices/loadedChats';
import { getCurrentUserDetails } from '../lib/localStorage';

const DEFAULT_LOOKBACK_DAYS = 90;

export const useChatThreads = (workspaceId: string) => {
  const storeDispatch = useAppDispatch();
  const { executeMutationWithToast } = useActionToast();
  const { chatThreads } = useAppSelector((store) => store.chatThreads.value);
  const userId = getCurrentUserDetails()?.id;

  const [fetch, { loading }] = useWorkspaceChatThreadsLazyQuery({
    variables: {
      id: workspaceId,
      params: {
        lookbackDays: DEFAULT_LOOKBACK_DAYS,
        userId,
      },
    },
  });

  const [fetchChatThread] = useWorkspaceChatThreadLazyQuery();
  const [editWSChatThread] = useEditWorkspaceChatThreadMutation();
  const [deleteWSChatThread] = useDeleteWorkspaceChatThreadMutation();

  const fetchChatThreads = useCallback(async () => {
    fetch()
      .then((res) => {
        if (res.data?.node?.__typename === 'Workspace') {
          const threads = res.data?.node?.chatThreads.edges.map(
            (edge) => edge.node,
          );
          if (threads) {
            storeDispatch(setChatThreads(threads));
          }
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }, [fetch, storeDispatch]);

  useEffect(() => {
    let isSubscribed = true;
    if (workspaceId && !chatThreads) {
      fetchChatThreads().then(() => {
        if (!isSubscribed) return;
      });
    }
    return () => {
      isSubscribed = false;
    };
  }, [chatThreads, fetchChatThreads, workspaceId]);

  const editChatThreadTitle = useCallback(
    async (threadId: string, title: string) => {
      const originTitle = chatThreads?.find(
        (thread) => thread.id === threadId,
      )?.title;

      const revertTitleChange = () => {
        storeDispatch(updateChatThreadTitle({ id: threadId, originTitle }));
        storeDispatch(
          updateLoadedChatByThreadId({
            chatThreadId: threadId,
            chatState: { chatTitle: originTitle },
          }),
        );
        return false;
      };

      // update the store first so the UI can reflect the change immediately
      storeDispatch(updateChatThreadTitle({ id: threadId, title }));
      storeDispatch(
        updateLoadedChatByThreadId({
          chatThreadId: threadId,
          chatState: { chatTitle: title },
        }),
      );

      executeMutationWithToast(
        () =>
          editWSChatThread({ variables: { input: { id: threadId, title } } }),
        'editChatThread',
        'Chat thread title updated.',
        'Failed to update chat thread title.',
        undefined,
        () => {
          revertTitleChange();
        },
      );
    },
    [chatThreads, editWSChatThread, executeMutationWithToast, storeDispatch],
  );

  const removeChatThread = useCallback(
    async (threadId: string) => {
      executeMutationWithToast(
        () => deleteWSChatThread({ variables: { input: { id: threadId } } }),
        'deleteChatThread',
        'Chat thread deleted.',
        'Failed to delete chat thread.',
        (data) => {
          if (data?.deleteChatThread.success) {
            storeDispatch(deleteChatThread({ id: threadId }));
          }
        },
      );
    },
    [deleteWSChatThread, executeMutationWithToast, storeDispatch],
  );

  // please do not depend on the chatThreads in the store
  // this will regenerate the fetchSelectedChatThread when a new chatThread is added
  // and this will reinitialize the chat thread in the useChat hook.
  const fetchSelectedChatThread = useCallback(
    async (chatThreadId: string) => {
      storeDispatch(setIsSelectedChatThreadLoaded(false));
      const response = await fetchChatThread({
        variables: {
          workspaceId,
          chatThreadId,
        },
      });

      if (response.data?.node?.__typename === 'Workspace') {
        if (response.data.node.chatThread.__typename === 'ChatThread') {
          storeDispatch(setIsSelectedChatThreadLoaded(true));
          return response.data.node.chatThread as ChatThread;
        }
      }
    },
    [fetchChatThread, storeDispatch, workspaceId],
  );

  const setSelectedChatThreadId = useCallback(
    (chatThreadId?: string) => {
      storeDispatch(setSelectedChatThreadIdAction(chatThreadId));
    },
    [storeDispatch],
  );

  return {
    loading,
    chatThreads,
    fetchChatThreads,
    editChatThreadTitle,
    removeChatThread,
    fetchSelectedChatThread,
    setSelectedChatThreadId,
  };
};
