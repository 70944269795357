// Dialog Registration
import CreateWorkspaceDialog, {
  CreateWorkspaceDialogProps,
} from './CreateWorkspaceDialog';
import CreateOrEditUserDialog, {
  CreateOrEditUserDialogProps,
} from './CreateOrEditUserDialog';
import EnvDataDialog, { EnvDataDialogProps } from './EnvDataDialog';
import FeatureDataDialog, { FeatureDataDialogProps } from './FeatureDataDialog';
import Information, { InformationProps } from './Information';
import WorkspaceDataDialog, {
  WorkspaceDataDialogProps,
} from './WorkspaceDataDialog';
import ActionLogEntryDataDialog, {
  ActionLogEntryDataDialogProps,
} from './ActionLogEntryDialog';
import SaveAsTemplateDialog, {
  SaveAsTemplateDialogProps,
} from './SaveAsTemplateDialog';
import SuccessDialog, { SuccessDialogProps } from './SuccessDialog';
import CreateEmbedTokenDialog, {
  CreateEmbedTokenDialogProps,
} from './CreateEmbedTokenDialog';
import DeleteEmbedTokenDialog, {
  DeleteEmbedTokenDialogProps,
} from './DeleteEmbedTokenDialog';
import RenameDialog, { RenameDialogProps } from './RenameDialog';
import ChatRatingReasons, { ChatRatingReasonsProps } from './ChatRatingReasons';
import ChatSettings, { ChatSettingsProps } from './ChatSettings';
import ColorPickerModalContent, {
  ColorPickerModalContentProps,
} from '../../components/shared/ColorPicker/ColorPickerModalContent';
import SaveToCreate, { SaveToCreateProps } from './SaveToCreate';
import SaveToWorkspace, { SaveToWorkspaceProps } from './SaveToWorkspace';
import CreateOrEditStackDialog, {
  CreateOrEditStackDialogProps,
} from './CreateOrEditStackDialog';
import ComponentInsightRatingReasons, {
  ComponentInsightRatingReasonsProps,
} from './ComponentInsightRatingReasons';

export enum DIALOG_IDS {
  CREATE_WORKSPACE = 'createWorkspace',
  CREATE_OR_EDIT_USER = 'createOrEditUser',
  ENV_DATA = 'envData',
  FEATURE_DATA = 'featureData',
  CONFIRMATION = 'confirmation',
  INFORMATION = 'information',
  WORKSPACE_DATA = 'workspaceData',
  ACTION_LOG_ENTRY_DATA = 'actionLogEntryData',
  SAVE_AS_TEMPLATE = 'saveAsTemplate',
  SUCCESS = 'success',
  CREATE_EMBED_TOKEN = 'createEmbedToken',
  DELETE_EMBED_TOKEN = 'deleteEmbedToken',
  RENAME = 'rename',
  CHAT_RATING_REASONS = 'chatRatingReasons',
  CHAT_SETTINGS = 'chatSettings',
  COLOR_PICKER = 'colorPicker',
  SAVE_TO_CREATE = 'saveToCreate',
  SAVE_TO_WORKSPACE = 'saveToWorkspace',
  CREATE_STACK = 'createStack',
  COMPONENT_INSIGHT_RATING_REASONS = 'componentInsightRatingReasons',
}

export type DialogComponentPropsMapping = {
  [DIALOG_IDS.CREATE_WORKSPACE]: CreateWorkspaceDialogProps;
  [DIALOG_IDS.CREATE_OR_EDIT_USER]: CreateOrEditUserDialogProps;
  [DIALOG_IDS.ENV_DATA]: EnvDataDialogProps;
  [DIALOG_IDS.FEATURE_DATA]: FeatureDataDialogProps;
  [DIALOG_IDS.CONFIRMATION]: InformationProps;
  [DIALOG_IDS.INFORMATION]: InformationProps;
  [DIALOG_IDS.WORKSPACE_DATA]: WorkspaceDataDialogProps;
  [DIALOG_IDS.ACTION_LOG_ENTRY_DATA]: ActionLogEntryDataDialogProps;
  [DIALOG_IDS.SAVE_AS_TEMPLATE]: SaveAsTemplateDialogProps;
  [DIALOG_IDS.SUCCESS]: SuccessDialogProps;
  [DIALOG_IDS.CREATE_EMBED_TOKEN]: CreateEmbedTokenDialogProps;
  [DIALOG_IDS.DELETE_EMBED_TOKEN]: DeleteEmbedTokenDialogProps;
  [DIALOG_IDS.RENAME]: RenameDialogProps;
  [DIALOG_IDS.CHAT_RATING_REASONS]: ChatRatingReasonsProps;
  [DIALOG_IDS.CHAT_SETTINGS]: ChatSettingsProps;
  [DIALOG_IDS.COLOR_PICKER]: ColorPickerModalContentProps;
  [DIALOG_IDS.SAVE_TO_CREATE]: SaveToCreateProps;
  [DIALOG_IDS.SAVE_TO_WORKSPACE]: SaveToWorkspaceProps;
  [DIALOG_IDS.CREATE_STACK]: CreateOrEditStackDialogProps;
  [DIALOG_IDS.COMPONENT_INSIGHT_RATING_REASONS]: ComponentInsightRatingReasonsProps;
};

export type Registry = {
  [id in DIALOG_IDS]: {
    Component: React.FC<DialogComponentPropsMapping[id]>;
    props?: DialogComponentPropsMapping[id];
  };
};

// Dialogs must be registered to be used
// default props are undefined because this registry don't have details about the props
export const DIALOG_REGISTRY: Registry = {
  [DIALOG_IDS.CREATE_WORKSPACE]: {
    Component: CreateWorkspaceDialog,
    props: undefined,
  },
  [DIALOG_IDS.CREATE_OR_EDIT_USER]: {
    Component: CreateOrEditUserDialog,
    props: undefined,
  },
  [DIALOG_IDS.ENV_DATA]: {
    Component: EnvDataDialog,
    props: undefined,
  },
  [DIALOG_IDS.FEATURE_DATA]: {
    Component: FeatureDataDialog,
    props: undefined,
  },
  [DIALOG_IDS.CONFIRMATION]: {
    Component: Information,
    props: undefined,
  },
  [DIALOG_IDS.INFORMATION]: {
    Component: Information,
    props: undefined,
  },
  [DIALOG_IDS.WORKSPACE_DATA]: {
    Component: WorkspaceDataDialog,
    props: undefined,
  },
  [DIALOG_IDS.ACTION_LOG_ENTRY_DATA]: {
    Component: ActionLogEntryDataDialog,
    props: undefined,
  },
  [DIALOG_IDS.SAVE_AS_TEMPLATE]: {
    Component: SaveAsTemplateDialog,
    props: undefined,
  },
  [DIALOG_IDS.SUCCESS]: {
    Component: SuccessDialog,
    props: undefined,
  },
  [DIALOG_IDS.CREATE_EMBED_TOKEN]: {
    Component: CreateEmbedTokenDialog,
    props: undefined,
  },
  [DIALOG_IDS.DELETE_EMBED_TOKEN]: {
    Component: DeleteEmbedTokenDialog,
    props: undefined,
  },
  [DIALOG_IDS.RENAME]: {
    Component: RenameDialog,
    props: undefined,
  },
  [DIALOG_IDS.CHAT_RATING_REASONS]: {
    Component: ChatRatingReasons,
    props: undefined,
  },
  [DIALOG_IDS.CHAT_SETTINGS]: {
    Component: ChatSettings,
    props: undefined,
  },
  [DIALOG_IDS.COLOR_PICKER]: {
    Component: ColorPickerModalContent,
    props: undefined,
  },
  [DIALOG_IDS.SAVE_TO_CREATE]: {
    Component: SaveToCreate,
    props: undefined,
  },
  [DIALOG_IDS.SAVE_TO_WORKSPACE]: {
    Component: SaveToWorkspace,
    props: undefined,
  },
  [DIALOG_IDS.CREATE_STACK]: {
    Component: CreateOrEditStackDialog,
    props: undefined,
  },
  [DIALOG_IDS.COMPONENT_INSIGHT_RATING_REASONS]: {
    Component: ComponentInsightRatingReasons,
    props: undefined,
  },
};
