import { useEffect } from 'react';
import { useWorkspaceFeatureActionLogEntriesLazyQuery } from '../generated/types';

const ACTION_LOG_ENTRIES_PER_PAGE = 20;

export const useFeatureActionLogs = ({
  workspaceId,
  workflowId,
}: {
  workspaceId: string;
  workflowId: string;
}) => {
  const [getFeatureActionLogs, { data, loading, error, fetchMore }] =
    useWorkspaceFeatureActionLogEntriesLazyQuery({
      variables: {
        workspaceId,
        params: {
          featureId: workflowId,
          pagination: {
            first: ACTION_LOG_ENTRIES_PER_PAGE,
          },
        },
      },
    });

  useEffect(() => {
    // should not fetch if workspaceId or workflowId is not defined
    // If workflowId is 'new', it means that the workflow is not created yet and thus no logs to fetch
    if (!workflowId || workflowId.includes('new') || !workspaceId) return;
    getFeatureActionLogs().catch((error) => console.error(error));
  }, [getFeatureActionLogs, workflowId, workspaceId]);

  return {
    data,
    loading,
    error,
    fetchMore,
  };
};
