import { useCallback } from 'react';
import { useToast } from './useToast';
import { MutationFunction } from '@apollo/client';
import { GraphQLError } from 'graphql';

export const useActionToast = () => {
  const { showSuccessToast, showErrorToast } = useToast();

  // Execute create, edit, delete mutations with success or error toast
  const executeMutationWithToast = useCallback(
    async <TData, TMutationKey extends keyof TData>(
      mutation: MutationFunction<TData>,
      mutationKey: TMutationKey,
      successMessage: string,
      errorMessage: string,
      successCallback?: (data?: TData) => void,
      errorCallback?: (errors?: readonly GraphQLError[]) => void,
    ) => {
      const onSuccess = (data: TData) => {
        showSuccessToast(successMessage);
        successCallback?.(data);
      };

      const onError = (errors?: readonly GraphQLError[]) => {
        showErrorToast(errorMessage);
        errorCallback?.(errors);
      };
      try {
        const result = await mutation();
        if (result.data) {
          const hasSuccess = (obj: unknown): obj is { success: boolean } => {
            return Object.prototype.hasOwnProperty.call(obj, 'success');
          };

          const mutationResult = result.data[mutationKey];

          // If has success property, use it to determine success/error
          if (hasSuccess(mutationResult)) {
            if (mutationResult.success) {
              onSuccess(result.data);
            } else {
              onError();
            }
          } else {
            // If no success property, result data success
            onSuccess(result.data);
          }
        } else {
          onError();
        }
      } catch {
        onError();
      }
    },
    [showErrorToast, showSuccessToast],
  );

  return { executeMutationWithToast };
};
