import { useEffect, useCallback } from 'react';
import { useApolloClient } from '@apollo/client';

import {
  useAppDispatch,
  useAppSelector,
  useEnvironmentComponents,
  useSessionInfo,
} from '../../hooks';
import {
  Component,
  ComponentType,
  MaterializationUpdatedDocument,
  MaterializationUpdatedSubscriptionResult,
  MaterializedComponent,
  useMaterializedComponentsLazyQuery,
} from '../../generated/types';
import {
  StackUIConfig,
  setStackUIConfigs as setStackUIConfigsAction,
  updateSuccessStackUIConfig as updateSuccessStackUIConfigAction,
  updateAbortedStackUIConfig as updateAbortedStackUIConfigAction,
  loadUserInteractionStateIDsFromStorage as loadUserInteractionStateIDsFromStorageAction,
  UserInteractionType,
} from '../../store/slices/stackUIConfigs';

// 1. fetch environment level stack component list
// 2. fetch workspace level stack component list (materialized)
// 3. When workspace level stack component list has pending list, start subscribing to the stack component list
// 4. When workspace level stack component list has completed list, stop subscribing to the stack component list

interface MaterializedComponentsBackgroundManagerProps {
  workspaceId: string;
}

const MaterializedComponentsBackgroundManager = ({
  workspaceId,
}: MaterializedComponentsBackgroundManagerProps) => {
  const client = useApolloClient();
  const dispatch = useAppDispatch();
  const { environment } = useAppSelector((state) => state.environment.value);
  const { email } = useSessionInfo();
  const environmentId = environment?.id;

  const envStackComponents = useAppSelector((state) =>
    environmentId
      ? state.environmentComponents.value[environmentId]?.stackComponents
      : undefined,
  );

  const { loadEnvironmentComponentsByType } = useEnvironmentComponents();

  useEffect(() => {
    if (envStackComponents && workspaceId) {
      // set initial StackUIConfigs for the workspace
      const stackUIConfigs: StackUIConfig[] = envStackComponents.map(
        (component: Component) => ({
          id: component.id,
          derivedTimestampedId: component.id, // env stack is not derived yet so we can't include the timestamp
          name: component.name,
          status: 'loading',
          isCurrentUserNotifyOptedIn: false,
        }),
      );
      dispatch(
        setStackUIConfigsAction({
          stackUIConfigs,
          workspaceId,
        }),
      );
    }
  }, [envStackComponents, dispatch, workspaceId]);

  // initialize the background manager
  // load the read component, insights, and dismissed insights ids to the redux store from local storage
  useEffect(() => {
    const typesToLoad: UserInteractionType[] = [
      'read-component',
      'read-insights',
      'dismissed-insights',
    ];
    typesToLoad.forEach((type) => {
      dispatch(
        loadUserInteractionStateIDsFromStorageAction({
          workspaceId,
          type,
        }),
      );
    });
  }, [dispatch, workspaceId]);

  // load stacks if they're not already loaded
  useEffect(() => {
    if (!envStackComponents && environmentId) {
      loadEnvironmentComponentsByType(environmentId, ComponentType.STACK);
    }
  }, [envStackComponents, loadEnvironmentComponentsByType, environmentId]);

  // fetch the materialized components for the workspace using the environment stack component ids
  const [getMaterializedComponents, { data: materializedComponentData }] =
    useMaterializedComponentsLazyQuery();

  // call the query to get the materialized components for the workspace
  // this is called when workspaceId changes
  useEffect(() => {
    if (
      environmentId &&
      envStackComponents &&
      envStackComponents.length > 0 &&
      workspaceId
    ) {
      const componentIds = envStackComponents.map((component) => component.id);
      if (componentIds.length > 0) {
        getMaterializedComponents({
          variables: {
            workspaceId,
            componentIds,
          },
        });
      }
    }
  }, [
    getMaterializedComponents,
    workspaceId,
    envStackComponents,
    dispatch,
    environmentId,
  ]);

  // This function is called when the materialized component is aborted
  const handleAbortedMaterializedComponentUpdate = useCallback(
    (componentId: string) => {
      dispatch(
        updateAbortedStackUIConfigAction({
          componentId,
          workspaceId,
        }),
      );
    },
    [dispatch, workspaceId],
  );

  // This function is called when the materialized component is received from the subscription or the query
  const handleMaterializedComponentUpdate = useCallback(
    (materializedComponent: MaterializedComponent) => {
      // handle the result by component type
      if (materializedComponent.component.type === ComponentType.STACK) {
        // If the materialized component has the record and has successful derived content
        // update the stackUIConfig as success
        if (materializedComponent.derivedContent) {
          dispatch(
            updateSuccessStackUIConfigAction({
              materializedComponent,
              workspaceId,
              userEmail: email,
            }),
          );
        } else {
          // If the materialized component does not have the record
          // update the stackUIConfig to aborted
          dispatch(
            updateAbortedStackUIConfigAction({
              componentId: materializedComponent.componentId,
              workspaceId,
            }),
          );
        }
      }
    },
    [dispatch, email, workspaceId],
  );

  const subscribeToUpdate = useCallback(
    (
      workspaceId: string,
      pendingIds: string[],
      successCallback?: (id: string) => void,
    ) => {
      // Keep track of remaining pending IDs
      const remainingIds = new Set(pendingIds);
      // Create subscription to monitor updates
      const subscriptionObservable = client.subscribe({
        query: MaterializationUpdatedDocument,
        variables: { workspaceId },
      });

      const subscription = subscriptionObservable.subscribe({
        next(result: MaterializationUpdatedSubscriptionResult) {
          const type = result.data?.materializationUpdated.__typename;
          if (type === 'MaterializationUpdateAborted') {
            const abortedComponentId =
              result.data?.materializationUpdated.componentId;
            if (abortedComponentId) {
              remainingIds.delete(abortedComponentId);
              handleAbortedMaterializedComponentUpdate(abortedComponentId);
            }
          } else if (type === 'MaterializedComponent') {
            const materializedUpdated =
              result.data?.materializationUpdated.__typename ===
              'MaterializedComponent'
                ? (result.data?.materializationUpdated as MaterializedComponent)
                : undefined;

            if (materializedUpdated) {
              handleMaterializedComponentUpdate(materializedUpdated);
              // Remove the ID from remaining set if it was pending
              remainingIds.delete(materializedUpdated.componentId);
              successCallback?.(materializedUpdated.componentId);
            }
          }

          // If no more pending IDs, clean up subscriptions
          if (remainingIds.size === 0) {
            subscription.unsubscribe();
          }
        },
        error(err) {
          console.error('Subscription error:', err);
          // Clean up on error
          subscription.unsubscribe();
        },
      });

      // Return cleanup function
      return () => {
        subscription.unsubscribe();
      };
    },
    [
      client,
      handleAbortedMaterializedComponentUpdate,
      handleMaterializedComponentUpdate,
    ],
  );

  // handle MaterializedComponents GQL query result
  useEffect(() => {
    if (
      materializedComponentData &&
      materializedComponentData.node?.__typename === 'Workspace' &&
      materializedComponentData.node.materializedComponents?.__typename ===
        'MaterializationResults'
    ) {
      const result = materializedComponentData.node.materializedComponents;
      if (result) {
        const { materialized, pendingIds } = result;
        materialized.forEach((materializedComponent) => {
          handleMaterializedComponentUpdate(
            materializedComponent as MaterializedComponent,
          );
        });

        if (pendingIds.length > 0) {
          const unsubscribe = subscribeToUpdate(workspaceId, pendingIds);
          return () => {
            unsubscribe?.();
          };
        }
      }
    }
  }, [
    dispatch,
    handleMaterializedComponentUpdate,
    materializedComponentData,
    subscribeToUpdate,
    workspaceId,
  ]);

  return null;
};

export default MaterializedComponentsBackgroundManager;
