import { createContext, ReactNode, useEffect, useMemo } from 'react';
import { useEnvironment, useSessionInfo } from '../../hooks';
import EnvironmentComponentsPreFetcher from '../EnvironmentComponentsPreFetcher';
interface EnvironmentContextStore extends ReturnType<typeof useEnvironment> {}

const initialState: EnvironmentContextStore = {
  initializeEnvironmentForSystemUser: async () => {
    /** */
  },
  loading: false,
  error: undefined,
  fetchEnvExplores: async () => {
    /** */
  },
  createEnvExplore: async () => {
    /** */
  },
  updateEnvExplore: async () => {
    /** */
  },
  deleteEnvExplore: async () => {
    /** */
  },
  envExploreLoading: false,
  envExploreError: undefined,
  createWorkspaceAddExplore: async () => {
    /** */
    return null;
  },
  deleteWorkspaceFromEnvironment: async () => {
    /** */
  },
  initializeEnvironmentForNonSystemUser: async () => {
    /** */
  },
  setDefaultEmbedAuth: async () => {
    /** */
  },
  updateBranding: async () => {
    /** */
  },
  environment: undefined,
  envExplores: undefined,
  envWorkspaceOptions: undefined,
  envWorkspaceLoading: false,
  defaultEmbedAuthId: null,
};

export const EnvironmentContext = createContext(initialState);
interface EnvironmentWrapperProps {
  children: ReactNode;
}
const EnvironmentProvider = ({ children }: EnvironmentWrapperProps) => {
  const { isSignedIn, email } = useSessionInfo();
  const value = useEnvironment();
  const memoizedValue = useMemo(() => value, [value]);
  const { initializeEnvironmentForNonSystemUser, environment } = memoizedValue;

  // Initialize environment for regular users (non-system users)
  // Note: System users have their environment initialized separately via environment selector
  useEffect(() => {
    // Reinitialize environment when user changes
    // This ensures we fetch fresh workspace data when switching between accounts
    if (isSignedIn) {
      initializeEnvironmentForNonSystemUser();
    }
  }, [isSignedIn, email, initializeEnvironmentForNonSystemUser]);

  return (
    <EnvironmentContext.Provider value={memoizedValue}>
      {children}
      {environment?.id && (
        <EnvironmentComponentsPreFetcher environmentId={environment?.id} />
      )}
    </EnvironmentContext.Provider>
  );
};

export default EnvironmentProvider;
