import { useAppDispatch, useAppSelector, useSessionInfo } from '../../hooks';
import {
  useCallback,
  useState,
  MouseEvent,
  useEffect,
  useContext,
  useMemo,
  SyntheticEvent,
} from 'react';
import { useEnvironmentListLazyQuery } from '../../generated/types';
import { EnvironmentContext } from '../EnvironmentProvider';
import Icon from '../shared/Icon';
import { WorkspaceContext } from '../WorkspaceProvider';
import { useRouter } from 'next/router';
import { setSystemSelectedEnvironmentId } from '../../store/slices/session';
import { getWorkspaceNavigationUrl } from '../../utils/getWorkspaceNavigationUrl';
import { isWorkspaceRelatedPage } from '../../utils/isWorkspacePage';
import { clearEnvWorkspaceOptions } from '../../store/slices/environment';
import SearchSelectPopper from '../shared/SearchSelectPopper';

type Option = {
  label: string;
  id: string;
};

const EnvironmentSelector = () => {
  const [selectedOption, setSelectedOption] = useState<Option | undefined>(
    undefined,
  );

  const [listData, setListData] = useState<Option[]>([]);

  const storeDispatch = useAppDispatch();
  const router = useRouter();
  const requireWorkspace = useMemo(
    () => isWorkspaceRelatedPage(router),
    [router],
  );
  const { workspace } = useAppSelector((state) => state.workspace.value);
  const { systemSelectedEnvironmentId } = useSessionInfo();

  const [fetchEnvironmentList, { data: envListData, loading }] =
    useEnvironmentListLazyQuery();

  const { environment, initializeEnvironmentForSystemUser } =
    useContext(EnvironmentContext);
  const { resetWorkspace } = useContext(WorkspaceContext);

  // update listData and selectedOption when environment changes
  useEffect(() => {
    if (envListData?.system.environments) {
      const newListData: Option[] = envListData.system.environments.map(
        (environment) => ({
          label: environment.name,
          id: environment.id,
        }),
      );
      setListData(newListData);

      if (environment?.id) {
        const selectedOption = newListData.find(
          (option) => option.id === environment.id,
        );
        setSelectedOption(selectedOption);
      }
    }
  }, [envListData, environment]);

  const selectEnvironment = useCallback(
    (option: Option) => {
      setSelectedOption(option);
      const envId = option.id;

      // first reset the workspace
      resetWorkspace();
      storeDispatch(clearEnvWorkspaceOptions());

      // then initialize the new environment
      initializeEnvironmentForSystemUser(envId);
      storeDispatch(setSystemSelectedEnvironmentId(envId));

      // handle navigation after state is cleared
      if (requireWorkspace) {
        const newUrl = getWorkspaceNavigationUrl(router.pathname);
        router.push(newUrl);
      }
    },
    [
      initializeEnvironmentForSystemUser,
      requireWorkspace,
      resetWorkspace,
      router,
      storeDispatch,
    ],
  );

  // Fetch environment list only if there's no data
  useEffect(() => {
    if (!envListData) {
      fetchEnvironmentList();
    }
  }, [envListData, fetchEnvironmentList]);

  const [hasInitialized, setHasInitialized] = useState(false);

  // Handle workspace-driven environment changes
  useEffect(() => {
    if (workspace?.id && workspace?.environment?.id) {
      initializeEnvironmentForSystemUser(workspace.environment.id);
      setHasInitialized(true);
    }
  }, [
    workspace?.id,
    workspace?.environment?.id,
    initializeEnvironmentForSystemUser,
  ]);

  // Otherwise, handle initial environment selection
  useEffect(() => {
    if (hasInitialized) {
      return;
    }

    if (systemSelectedEnvironmentId) {
      initializeEnvironmentForSystemUser(systemSelectedEnvironmentId);
      setHasInitialized(true);
    } else if (listData.length > 0) {
      initializeEnvironmentForSystemUser(listData[0].id);
      setHasInitialized(true);
    }
  }, [
    hasInitialized,
    systemSelectedEnvironmentId,
    listData,
    initializeEnvironmentForSystemUser,
  ]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpen = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  }, [anchorEl]);

  const handleAutoCompleteChange = useCallback(
    (_: SyntheticEvent, option: Option | null) => {
      if (!option) {
        // when user clears the input, do nothing, keep the popup open.
        return;
      }

      handleClose();
      if (option?.id === environment?.id) {
        // environment is already selected
        return;
      }
      selectEnvironment(option);
    },
    [environment, handleClose, selectEnvironment],
  );

  const open = Boolean(anchorEl);

  return (
    <>
      <Icon
        data-testid='environment-selector-caret-down'
        name='carat-down'
        onClick={handleOpen}
        sx={{ cursor: 'pointer', ml: 1 }}
        color='icon.main'
      />
      <SearchSelectPopper
        options={listData}
        onChange={handleAutoCompleteChange}
        value={selectedOption}
        loading={loading}
        noOptionsText='No environments found'
        getOptionLabel={(option) => option.label}
        // Popper props
        anchorEl={anchorEl}
        open={open}
        placement='bottom-start'
        // ClickAwayListener props
        onClickAway={handleClose}
        // InputBase props
        placeholder='Search environments'
      />
    </>
  );
};

export default EnvironmentSelector;
