import { useCallback, useEffect } from 'react';
import { useEnvironmentComponents } from '../../hooks';

const EnvironmentComponentsPreFetcher = ({
  environmentId,
}: {
  environmentId: string;
}) => {
  const { loadEnvironmentComponentsByType } = useEnvironmentComponents();

  const loadAllEnvironmentComponents = useCallback(async () => {
    await loadEnvironmentComponentsByType(environmentId);
  }, [environmentId, loadEnvironmentComponentsByType]);

  useEffect(() => {
    if (environmentId) {
      loadAllEnvironmentComponents();
    }
  }, [
    loadEnvironmentComponentsByType,
    environmentId,
    loadAllEnvironmentComponents,
  ]);

  return null;
};

export default EnvironmentComponentsPreFetcher;
