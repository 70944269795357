import { useCallback, useMemo, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Box, Stack, SxProps, Typography } from '@mui/material';
// components
import Icon from '../../shared/Icon';
import IconButton from '../../shared/IconButton';
import Button from '../../shared/Button';

import { IconName } from '../../shared/Icon/types';
import { GLOBAL_CONTENT_WIDTH } from '../constants';
import { Routes } from '@madeinventive/core-types';
// hooks
import { useAppSelector } from '../../../hooks';
import { Theme } from '@mui/system';

type NavButtonConfig = {
  id: string;
  label: string;
  icon: IconName;
  route: string;
};

const SUBSCRIPTION_NOTIFICATION_DELAY = 5000;
const NAB_BAR_PADDING = 16;

const WorkspaceNavBar = ({
  workspaceId,
  isMobile,
}: {
  workspaceId: string;
  isMobile: boolean;
}) => {
  const router = useRouter();
  const isWorkspaceHome = router.pathname === '/workspace/[workspaceId]';
  const [showDelayMessage, setShowDelayMessage] = useState(false);
  const stackUIConfigs = useAppSelector(
    (state) => state.stackUIConfigs.value[workspaceId]?.stackUIConfigs,
  );
  const isSubscribing = stackUIConfigs?.some(
    (config) => config.status === 'loading',
  );

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    // show the delay message only if we are on the workspace home page
    if (isSubscribing && isWorkspaceHome) {
      timeoutId = setTimeout(() => {
        setShowDelayMessage(true);
      }, SUBSCRIPTION_NOTIFICATION_DELAY);
    } else {
      setShowDelayMessage(false);
    }
    return () => clearTimeout(timeoutId);
  }, [isWorkspaceHome, isSubscribing]);

  const WorkspaceNavConfigs: NavButtonConfig[] = [
    {
      id: 'home',
      label: 'Explore',
      icon: 'stack',
      route: `/workspace/${workspaceId}`,
    },
    {
      id: 'chatHistory',
      label: 'History',
      icon: 'history',
      route: `/workspace/${workspaceId}/chatHistory`,
    },
    {
      id: 'savedComponents',
      label: 'Saved',
      icon: 'bookmark',
      route: `/workspace/${workspaceId}/components`,
    },
    {
      id: 'alerts',
      label: 'Alerts',
      icon: 'bell-bolt',
      route: `/workspace/${workspaceId}/features`,
    },
  ];

  const navBarStyle = useMemo<SxProps<Theme>>(
    () =>
      isMobile
        ? {
            width: '100%',
            justifyContent: 'center',
            borderTop: (theme) => `1px solid ${theme.palette.border.light}`,
            borderRight: 'none',
            paddingX: `${NAB_BAR_PADDING}px`,
            paddingY: `${NAB_BAR_PADDING / 2}px`,
          }
        : {
            width: 'auto',
            justifyContent: 'flex-start',
            borderTop: 'none',
            borderRight: (theme) => `1px solid ${theme.palette.border.light}`,
            padding: `${NAB_BAR_PADDING}px`,
          },
    [isMobile],
  );

  const navButtonWrapperStyle = useMemo<SxProps>(
    () =>
      isMobile
        ? {
            flexDirection: 'row',
            justifyContent: 'space-between',
          }
        : {
            flexDirection: 'column',
            justifyContent: 'flex-start',
          },
    [isMobile],
  );

  const chatButtonContainerStyle = useMemo<SxProps>(
    () =>
      isMobile
        ? {
            top: -NAB_BAR_PADDING,
            left: NAB_BAR_PADDING,
            transform: 'translate(0%, -100%)', // move the chat button container up by the height of the navbar
          }
        : {
            bottom: NAB_BAR_PADDING,
            left: NAB_BAR_PADDING,
            width: 'max-content', // prevent the delay message from shrinking
          },
    [isMobile],
  );

  const hideNewChatButton =
    isMobile && router.pathname === '/workspace/[workspaceId]/chat';

  return (
    <Stack
      id='workspace-navigation'
      sx={{
        position: 'relative',
        boxShadow: (theme) => theme.customShadows[1],
        ...navBarStyle,
      }}
    >
      <Stack
        id='workspace-navigation-buttons'
        overflow='auto'
        maxWidth={GLOBAL_CONTENT_WIDTH}
        width='100%'
        spacing={isMobile ? 0 : 3}
        sx={{
          ...navButtonWrapperStyle,
        }}
      >
        {WorkspaceNavConfigs.map((config) => (
          <WorkspaceNavButton key={config.id} {...config} isMobile={isMobile} />
        ))}
      </Stack>
      {!hideNewChatButton && (
        <Stack
          id='chat-button-container'
          direction='row'
          alignItems='center'
          spacing={1}
          sx={{
            position: 'absolute',
            ...chatButtonContainerStyle,
          }}
        >
          <IconButton
            size='large'
            iconName='chat-new'
            variant='contained'
            onClick={() => {
              router.push(Routes.chat(workspaceId));
            }}
            sx={{
              borderWidth: 1,
              borderStyle: 'solid',
              backgroundColor: (theme) => theme.palette.primary.lighter,
              borderColor: (theme) => theme.palette.border.active, // override the default border color
              boxShadow: (theme) => `${theme.customShadows[1]} !important`,
              '&:hover': {
                backgroundColor: (theme) => theme.palette.primary.main,
                boxShadow: (theme) => `${theme.customShadows[3]} !important`,
              },
            }}
          />
          {showDelayMessage && (
            <Box
              sx={{
                backgroundColor: (theme) => theme.palette.neutrals.black,
                borderRadius: 2,
                paddingX: 1,
                paddingY: 0.5,
              }}
            >
              <Typography
                variant='h6'
                sx={{ color: (theme) => theme.palette.neutrals.white }}
              >
                Have a question? Start a chat while you wait
              </Typography>
            </Box>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default WorkspaceNavBar;

const WorkspaceNavButton = ({
  id,
  label,
  icon,
  route,
  isMobile,
}: NavButtonConfig & { isMobile: boolean }) => {
  const router = useRouter();
  const isSelected = useMemo(() => {
    if (id === 'home') {
      return router.asPath === route;
    } else {
      return router.asPath.includes(route);
    }
  }, [id, router, route]);

  const handleClick = useCallback(() => {
    router.push(route);
  }, [route, router]);

  const buttonStyleByBreakpoint = isMobile
    ? {
        borderRadius: 60, //240px
        maxWidth: 128,
      }
    : {
        minWidth: 'unset',
        borderRadius: 2, //8px
        width: 40,
        height: 40,
        padding: 0,
      };

  return (
    <Stack direction='column' alignItems='center' spacing={0.5} width='100%'>
      <Button
        variant='text'
        fullWidth
        disableRipple
        size='small'
        aria-label={`${id}-${isSelected}`}
        className={isSelected ? 'active' : ''}
        sx={{
          ...buttonStyleByBreakpoint,
          '&.active': {
            backgroundColor: 'primary.wash',
            border: (theme) => `1px solid ${theme.palette.border.active}`,
          },
        }}
        onClick={handleClick}
      >
        <Icon name={icon} size='small' />
      </Button>
      <Typography variant='caption'>{label}</Typography>
    </Stack>
  );
};
