import { useState, useCallback, useContext } from 'react';
import { Field, Form, Formik } from 'formik';
import FormFieldLabel from '../shared/Form/FormFieldLabel';
import {
  Box,
  Stack,
  TextField,
  Typography,
  Grid,
  Tooltip,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import * as Yup from 'yup';

import Alert from '../shared/Alert';
import Button from '../shared/Button';
import Switch from '../shared/Switch';
import {
  useCreateEmbedAuthorizationMutation,
  EnvironmentEmbedAuthorizationsDocument,
} from '../../generated/types';
import { BaseRegisteredDialogComponentProps } from './types';
import { EnvironmentContext } from '../EnvironmentProvider';
import { useAppDispatch } from '../../hooks';
import { setDefaultEmbedAuthId } from '../../store/slices/environment';

export interface CreateEmbedTokenDialogProps
  extends BaseRegisteredDialogComponentProps {}

const initialFormValues = {
  name: '',
  hostUrl: '',
  scope: ['chat'], // The UI for this is removed, but the backend still expects it with a default value
  setAsDefault: false,
};

const validationSchema = Yup.object({
  hostUrl: Yup.string()
    .matches(
      /^https?:\/\/(?:localhost(?::[0-9]+)?|[^\s:/?#]+(?::[0-9]+)?(?:\/[^\s]*)?$)/,
      'Please enter a valid URL',
    )
    .required('Host URL is required'),
});

const CreateEmbedTokenDialog = ({
  hideDialog,
}: CreateEmbedTokenDialogProps) => {
  const storeDispatch = useAppDispatch();

  const [createTokenMutation] = useCreateEmbedAuthorizationMutation({
    refetchQueries: [EnvironmentEmbedAuthorizationsDocument],
  });
  const [generatedToken, setGeneratedToken] = useState<string>('');
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const { environment } = useContext(EnvironmentContext);
  const environmentId = environment?.id ?? '';

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(generatedToken);
    setIsCopied(true);
  }, [generatedToken]);

  const handleFormSubmit = useCallback(
    async (values: typeof initialFormValues) => {
      const convertedScopes = values.scope.reduce(
        (acc, scope) => ({ ...acc, [scope]: true }),
        {},
      );
      const result = await createTokenMutation({
        variables: {
          input: {
            name: values.name,
            environmentId: environmentId,
            authorizedHostUrl: values.hostUrl,
            authorizedScopes: convertedScopes,
            setAsDefault: values.setAsDefault,
          },
        },
      });

      const created = result.data?.createEmbedAuthorization;
      if (created) {
        setGeneratedToken(created.authToken);
        if (created.environment.deepLinkAuthorization?.id) {
          // update redux to exactly the deep link authorization the environment knows
          storeDispatch(
            setDefaultEmbedAuthId(created.environment.deepLinkAuthorization.id),
          );
        }
      }
    },
    [createTokenMutation, environmentId, storeDispatch],
  );

  return generatedToken ? (
    <Stack alignItems='center' spacing={2} px={1} pt={2} pb={4}>
      <Alert severity='info' sx={{ width: '100%' }}>
        This authorization token is only shown once. Copy it to your clipboard
        for safekeeping.
      </Alert>
      <Box
        sx={{
          border: 1,
          borderRadius: 3,
          borderColor: 'border.light',
          p: 2,
          width: '100%',
        }}
      >
        <Grid
          container
          direction='row'
          spacing={1}
          alignItems='center'
          width='100%'
        >
          <Grid item xs sx={{ minWidth: 0 }}>
            <Tooltip title={generatedToken} arrow>
              <Typography
                fontWeight='bold'
                color='text.secondary'
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {generatedToken}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item>
            <Button
              size='small'
              variant='text'
              startIcon={isCopied ? <CheckIcon /> : <ContentCopyIcon />}
              onClick={copyToClipboard}
              sx={{ minWidth: 'auto' }}
            >
              {isCopied ? 'Copied' : 'Copy'}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  ) : (
    <Formik
      initialValues={initialFormValues}
      onSubmit={handleFormSubmit}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue, touched, errors, isValid, dirty }) => (
        <Form>
          <Stack spacing={1} mb={1}>
            <FormFieldLabel text='Host URL' required />
            <Typography variant='body2'>
              Specify where Inventive will appear in your app so we can enable
              security permissions and enhanced features like shareable links
              and email notifications without requiring additional setup.
            </Typography>
            <Switch
              label='Default authorization for email deep links'
              checked={values.setAsDefault}
              onChange={(checked: boolean) =>
                setFieldValue('setAsDefault', checked)
              }
              size='small'
            />
            <Field
              as={TextField}
              name='hostUrl'
              size='small'
              fullWidth
              required
              error={touched.hostUrl && Boolean(errors.hostUrl)}
              helperText={touched.hostUrl && errors.hostUrl}
            />
          </Stack>
          <Stack direction='row' justifyContent='flex-end' py={2} spacing={1.5}>
            <Button variant='outlined' onClick={hideDialog}>
              Cancel
            </Button>
            <Button
              variant='contained'
              type='submit'
              disabled={!isValid || !dirty}
            >
              Generate token
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default CreateEmbedTokenDialog;
