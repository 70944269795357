import { useMemo, forwardRef, ForwardedRef } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  CircularProgress,
  TypographyProps,
} from '@mui/material';

type ButtonVariant = 'contained' | 'outlined' | 'text';
type ButtonSize = 'small' | 'large';
type ButtonState = 'default' | 'hovered' | 'focused' | 'disabled' | 'selected';
type ButtonProperty = 'border' | 'background' | 'font' | 'icon';

export interface ButtonProps extends Omit<MuiButtonProps, 'color'> {
  variant?: ButtonVariant;
  size?: ButtonSize;
  isLoading?: boolean;
}

type SizesForButton = {
  paddingX: string;
  paddingY: string;
  spinnerSize: string;
  iconSize: string;
  fontSize: TypographyProps['fontSize'];
};

const Button = forwardRef(
  (props: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const {
      children,
      variant = 'contained',
      size = 'large',
      isLoading,
      sx,
      ...rest
    } = props;

    const theme = useTheme();

    /// keep this in the component because story file is not able to import theme
    const buttonColorMap: Record<
      ButtonVariant,
      Record<ButtonState, Record<ButtonProperty, string>>
    > = useMemo(
      () => ({
        contained: {
          default: {
            border: theme.palette.primary.dark,
            background: theme.palette.primary.lighter,
            font: theme.palette.text.primary,
            icon: theme.palette.icon.main,
          },
          hovered: {
            border: theme.palette.border.active,
            background: theme.palette.primary.main,
            font: theme.palette.text.primary,
            icon: theme.palette.icon.main,
          },
          focused: {
            border: theme.palette.primary.dark,
            background: theme.palette.primary.wash,
            font: theme.palette.text.primary,
            icon: theme.palette.icon.main,
          },
          disabled: {
            border: theme.palette.border.disabled,
            background: theme.palette.background.disabled,
            font: theme.palette.text.tertiary,
            icon: theme.palette.icon.disabled,
          },
          selected: {
            border: theme.palette.primary.dark,
            background: theme.palette.primary.lighter,
            font: theme.palette.text.primary,
            icon: theme.palette.icon.main,
          },
        },
        outlined: {
          default: {
            border: theme.palette.border.actionable,
            background: theme.palette.background.paper,
            font: theme.palette.text.primary,
            icon: theme.palette.icon.main,
          },
          hovered: {
            border: theme.palette.border.active,
            background: theme.palette.primary.wash,
            font: theme.palette.text.primary,
            icon: theme.palette.icon.main,
          },
          focused: {
            border: theme.palette.border.actionable,
            background: theme.palette.background.paper,
            font: theme.palette.text.primary,
            icon: theme.palette.icon.main,
          },
          disabled: {
            border: theme.palette.background.disabled,
            background: theme.palette.background.paper,
            font: theme.palette.text.tertiary,
            icon: theme.palette.icon.disabled,
          },
          selected: {
            border: theme.palette.border.active,
            background: theme.palette.primary.wash,
            font: theme.palette.text.primary,
            icon: theme.palette.icon.main,
          },
        },
        text: {
          default: {
            border: 'transparent',
            background: 'transparent',
            font: theme.palette.text.primary,
            icon: theme.palette.icon.main,
          },
          hovered: {
            border: 'transparent',
            background: theme.palette.primary.wash,
            font: theme.palette.text.primary,
            icon: theme.palette.icon.main,
          },
          focused: {
            border: 'transparent',
            background: 'transparent',
            font: theme.palette.text.primary,
            icon: theme.palette.icon.main,
          },
          disabled: {
            border: 'transparent',
            background: 'transparent',
            font: theme.palette.text.tertiary,
            icon: theme.palette.icon.disabled,
          },
          selected: {
            border: 'transparent',
            background: theme.palette.background.default,
            font: theme.palette.text.primary,
            icon: theme.palette.icon.main,
          },
        },
      }),
      [theme],
    );

    const sizesByButtonSize = useMemo(() => {
      const map: Record<ButtonSize, SizesForButton> = {
        small: {
          paddingX: '8px',
          paddingY: '4px',
          spinnerSize: '16px',
          iconSize: '16px',
          fontSize: theme.typography.h6.fontSize,
        },
        large: {
          paddingX: '12px',
          paddingY: '8px',
          spinnerSize: '20px',
          iconSize: '20px',
          fontSize: theme.typography.h5.fontSize,
        },
      };
      return map[size];
    }, [size, theme]);

    return (
      <MuiButton
        ref={ref}
        variant={variant}
        disabled={isLoading}
        sx={{
          borderRadius: '8px',
          padding: `${sizesByButtonSize.paddingY} ${sizesByButtonSize.paddingX}`,
          borderWidth: '1px',
          borderStyle: 'solid',
          boxShadow: 'none !important',
          fontSize: sizesByButtonSize.fontSize,
          // default/selected color
          borderColor: buttonColorMap[variant].default.border,
          backgroundColor: buttonColorMap[variant].default.background,
          color: buttonColorMap[variant].default.font,
          '& .MuiButton-endIcon>*:nth-of-type(1), & .MuiButton-startIcon>*:nth-of-type(1)':
            {
              opacity: isLoading ? 0 : 1,
              fontSize: sizesByButtonSize.iconSize,
              color: buttonColorMap[variant].default.icon,
            },
          // hover color
          '&:hover': {
            borderColor: buttonColorMap[variant].hovered.border,
            backgroundColor: buttonColorMap[variant].hovered.background,
            color: buttonColorMap[variant].hovered.font,
            '& .MuiButton-endIcon>*:nth-of-type(1), & .MuiButton-startIcon>*:nth-of-type(1)':
              {
                color: buttonColorMap[variant].hovered.icon,
              },
          },
          // focus color
          '&:focus': {
            borderColor: buttonColorMap[variant].focused.border,
            backgroundColor: buttonColorMap[variant].focused.background,
            color: buttonColorMap[variant].focused.font,
            '& .MuiButton-endIcon>*:nth-of-type(1), & .MuiButton-startIcon>*:nth-of-type(1)':
              {
                color: buttonColorMap[variant].focused.icon,
              },
          },
          // disabled color
          '&.Mui-disabled': {
            borderColor: buttonColorMap[variant].disabled.border,
            backgroundColor: buttonColorMap[variant].disabled.background,
            color: buttonColorMap[variant].disabled.font,
            '& .MuiButton-endIcon>*:nth-of-type(1), & .MuiButton-startIcon>*:nth-of-type(1)':
              {
                color: buttonColorMap[variant].disabled.icon,
              },
          },
          ...sx,
        }}
        {...rest}
      >
        {typeof children === 'string' || props.title ? (
          <span
            style={{
              opacity: isLoading ? 0 : 1,
            }}
          >
            {children || props.title}
          </span>
        ) : (
          children
        )}
        {isLoading && (
          <CircularProgress
            color='neutral'
            size={sizesByButtonSize.spinnerSize}
            sx={{
              position: 'absolute',
              transform: 'translate(-50%, -50%) !important',
              left: '50%',
              top: '50%',
            }}
          />
        )}
      </MuiButton>
    );
  },
);

Button.displayName = 'Button';

export default Button;
