import { useCallback, useContext } from 'react';
import { useAppSelector } from './store';
import { EnvironmentContext } from '../components/EnvironmentProvider';

export const useSaveReferences = () => {
  const { environment } = useContext(EnvironmentContext);

  const envComponentsState = useAppSelector(
    (store) => store.environmentComponents.value,
  );
  const wsComponentsState = useAppSelector(
    (store) => store.workspaceComponents.value,
  );

  const isSavedToCreate = useCallback(
    (entityId: string) => {
      if (environment) {
        const envComponents = envComponentsState[environment.id];
        if (
          (envComponents.dataComponents ?? []).some(
            (component) => component.originSourceId === entityId,
          )
        ) {
          return true;
        }
      }
      return false;
    },
    [environment, envComponentsState],
  );

  const isSavedToWorkspace = useCallback(
    (entityId: string) => {
      const { components } = wsComponentsState;
      if (
        (components ?? []).some(
          (component) => component.originSourceId === entityId,
        )
      ) {
        return true;
      }
      return false;
    },
    [wsComponentsState],
  );

  return {
    isSavedToCreate,
    isSavedToWorkspace,
  };
};
