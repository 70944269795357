import { SxProps } from '@mui/material';
import IconButton from '../IconButton';

interface PanelCloseButtonProps {
  isMobile: boolean;
  onClose: () => void;
  hideHeader?: boolean; // when true, the button is positioned absolutely on top right
}

const PanelCloseButton = ({
  isMobile,
  onClose,
  hideHeader,
}: PanelCloseButtonProps) => {
  const hiddenStyle: SxProps = {
    position: 'absolute',
    top: 8,
    ...(isMobile
      ? { left: 4 } // Mobile: caret on left
      : { right: 8 }), // Desktop: X on right
  };
  return (
    <IconButton
      iconName={isMobile ? 'carat-left' : 'close'}
      aria-label='close'
      onClick={onClose}
      size='small'
      sx={hideHeader ? hiddenStyle : {}}
    />
  );
};

export default PanelCloseButton;
