import MuiTooltip, { TooltipProps } from '@mui/material/Tooltip';

const Tooltip = ({ children, title, ...rest }: TooltipProps) => {
  if (!title) return children;
  return (
    <MuiTooltip
      title={title}
      slotProps={{
        tooltip: {
          sx: {
            borderRadius: 1,
            paddingX: 1,
            paddingY: 0.5,
            whiteSpace: 'pre-line',
            backgroundColor: 'neutrals.black',
            color: 'neutrals.white',
          },
        },
      }}
      {...rest}
    >
      {children}
    </MuiTooltip>
  );
};

export default Tooltip;
