import { BaseSyntheticEvent, ChangeEvent, useState, useCallback } from 'react';
import {
  Box,
  FormControl,
  Stack,
  TextField,
  Typography,
  Link,
} from '@mui/material';
import { debounce } from 'min-dash';
import { PlaceholderLocation, useFeature } from '../../../hooks';
import UpdatingIndicator from './UpdatingIndicator';
import FormFieldLabel from '../../shared/Form/FormFieldLabel';
import AppearanceSelector from '../../appearance/AppearanceSelector';
import AppearanceConfigs from '../../appearance/AppearanceConfigs';
import {
  FieldAppearanceType,
  FieldAppearanceConfig,
  isValid,
  TagMetaData,
  validators,
} from '@madeinventive/core-types';
import VFPInputField from '../../VFPInputField';
import { useRouter } from 'next/router';
import { extractFieldDataFieldNames } from '../../../store/slices/features';
import { useDrawer } from '../../../hooks/useDrawer';
import { DRAWER_IDS } from '../../registeredDrawers/drawerRegistry';
export interface EditColumnFormProps {
  colIndex: number;
  defaultColumnName: string;
}

const editTableDrawerContentProps = {
  inputsDisabled: false,
  openedAccordionId: 'data-accordion',
};

const EditColumnForm = ({
  colIndex,
  defaultColumnName,
}: EditColumnFormProps) => {
  const { showDrawer } = useDrawer();

  const { featureEditData, updateColumnFieldAt } = useFeature();
  const workspaceId = useRouter().query.workspaceId as string | undefined;

  const fieldDatum = featureEditData.fieldData[colIndex];

  const [savedColIndex, setSavedColIndex] = useState<number>();

  const [updating, setUpdating] = useState(false);

  const selectedFields = extractFieldDataFieldNames(featureEditData.fieldData);

  const debouncedHandleChange = debounce((e: BaseSyntheticEvent) => {
    updateColumnFieldAt(colIndex, {
      ...fieldDatum,
      label: e.target.value,
    });
    setUpdating(false);
  }, 300);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setUpdating(true);
      debouncedHandleChange(e);
    },
    [debouncedHandleChange],
  );

  // ensures reset component if switching between columns
  if (colIndex !== savedColIndex) {
    setSavedColIndex(colIndex);
  }

  const handleWithTimeout = useCallback((callback: () => void) => {
    setUpdating(true);
    setTimeout(() => {
      callback();
      setUpdating(false);
    }, 200);
  }, []);

  const handleVFPChange = useCallback(
    (field: TagMetaData | undefined) => {
      handleWithTimeout(() => {
        if (field === undefined) {
          updateColumnFieldAt(colIndex, { ...fieldDatum, variable: undefined });
        }
        if (field && isValid<TagMetaData>(validators.TagMetaData, field)) {
          updateColumnFieldAt(colIndex, { ...fieldDatum, variable: field });
        }
      });
    },
    [colIndex, fieldDatum, handleWithTimeout, updateColumnFieldAt],
  );

  const handleAppearanceChange = useCallback(
    (appearance: FieldAppearanceType) =>
      handleWithTimeout(() => {
        updateColumnFieldAt(colIndex, { ...fieldDatum, appearance });
      }),
    [colIndex, fieldDatum, handleWithTimeout, updateColumnFieldAt],
  );

  const handleAppearanceConfigChange = useCallback(
    (appearanceConfig: FieldAppearanceConfig) => {
      updateColumnFieldAt(colIndex, { ...fieldDatum, appearanceConfig });
    },
    [colIndex, fieldDatum, updateColumnFieldAt],
  );

  const openEditTableDrawer = useCallback(() => {
    showDrawer({
      id: DRAWER_IDS.TABLE_APP_EDIT_TABLE,
      title: 'Feature Properties',
      contentProps: editTableDrawerContentProps,
    });
  }, [showDrawer]);

  if (!fieldDatum) return null;

  return (
    <Stack direction='column' spacing={4} pt={4}>
      <UpdatingIndicator updating={updating} />
      <FormControl fullWidth>
        <Stack spacing={2}>
          <FormFieldLabel
            text='Header'
            subText='Choose what name shows at the top of the column in the table.'
            required
            variant='section'
          />
          <TextField
            size='small'
            defaultValue={defaultColumnName}
            onChange={handleChange}
          />
        </Stack>
      </FormControl>
      <FormControl fullWidth>
        <Stack spacing={2}>
          <Box>
            <FormFieldLabel
              text='Configuration'
              subText={
                featureEditData.exploreId
                  ? 'Select the data field for this column.'
                  : ''
              }
              variant='section'
              required
            />
            {!featureEditData.exploreId && (
              <Typography
                mt={0.5}
                sx={{
                  color: 'primary.light',
                }}
              >
                No data selected yet - use a placeholder or add data{' '}
                <Link onClick={openEditTableDrawer} sx={{ cursor: 'pointer' }}>
                  here
                </Link>
              </Typography>
            )}
          </Box>
          {workspaceId && (
            <VFPInputField
              fieldVariable={fieldDatum.variable}
              setFieldVariable={handleVFPChange}
              workspaceId={workspaceId}
              placeholder='Find a data field'
              selectedFields={selectedFields}
              placeholderLocation={PlaceholderLocation.FIELD_DATA}
              disabled={false}
            />
          )}
        </Stack>
      </FormControl>
      <FormControl fullWidth>
        <Stack spacing={2} marginBottom={3}>
          <FormFieldLabel
            text='Appearance'
            subText='Choose how the values in this column will be displayed.'
            variant='section'
          />
          <AppearanceSelector
            appearanceType={
              fieldDatum.appearance || FieldAppearanceType.PLAIN_TEXT
            }
            setAppearanceType={handleAppearanceChange}
          />
        </Stack>
      </FormControl>
      <AppearanceConfigs
        selectedAppearanceType={
          fieldDatum.appearance || FieldAppearanceType.PLAIN_TEXT
        }
        selectedFieldDatum={fieldDatum}
        appearanceConfig={fieldDatum.appearanceConfig || {}}
        setAppearanceConfig={handleAppearanceConfigChange}
      />
    </Stack>
  );
};

export default EditColumnForm;
