import {
  DialogActions as MuiDialogActions,
  DialogActionsProps as MuiDialogActionsProps,
} from '@mui/material';
import { DialogActionType } from '../../../contexts/DialogProvider';
import Button from '../Button';

// isBuiltIn: used to determine its padding.
// When it is built in by Dialog and is a direct child of Dialog, Dialog > DialogAction
// it can have proper paddings.
// when it is added by DialogContent and is a child of DialogContext, Dialog > DialogContext > DialogAction,
// it should have 0 padding to mitigate the padding from DialogContext
// but it should give a vertical space between Content and Actions
// In most cases, isBuiltIn should be false or undefined.
interface DialogActionsProps extends MuiDialogActionsProps {
  secondaryAction?: DialogActionType;
  primaryAction?: DialogActionType;
  closeDialog: () => void;
  isBuiltIn?: boolean;
}

const DialogActions = ({
  secondaryAction,
  primaryAction,
  closeDialog,
  isBuiltIn,
  ...rest
}: DialogActionsProps) => {
  const handleSecondaryButtonClick = () => {
    secondaryAction?.action && secondaryAction.action();
    closeDialog();
  };

  const handlePrimaryButtonClick = async () => {
    if (primaryAction?.action) {
      primaryAction.action();
      closeDialog();
    }
  };

  return (
    <MuiDialogActions sx={isBuiltIn ? { p: 2 } : { p: 0, pt: 4 }} {...rest}>
      {secondaryAction ? (
        <Button
          variant='outlined'
          onClick={handleSecondaryButtonClick}
          disabled={secondaryAction.disabled}
        >
          {secondaryAction.text}
        </Button>
      ) : null}
      {primaryAction ? (
        <Button
          type='submit'
          variant='contained'
          onClick={handlePrimaryButtonClick}
          disabled={primaryAction.disabled}
        >
          {primaryAction.text}
        </Button>
      ) : null}
    </MuiDialogActions>
  );
};

export default DialogActions;
